import React from 'react';
import { Grid, Icon, Paper, Typography } from '@mui/material';

const ProductCard = ({ image, title, children }) => {
  return (
    <Paper elevation={2}>
      <Grid container spacing={0} alignItems='center'>
        <Grid item xs={2} sm={3} alignItems='center' alignContent='center'>
          <Paper elevation={0} sx={{ padding: 2, textAlign: 'center' }}>
            <Icon
              component={image}
              sx={{ fontSize: 48, color: 'primary.main' }}
            />
          </Paper>
        </Grid>
        <Grid item xs={10} sm={9}>
          <Paper elevation={0} sx={{ padding: 2 }}>
            <Typography variant='h5' textAlign='center' color='primary'>
              {title}
            </Typography>
            <Typography variant='smallerFont'>{children}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProductCard;
