import React from 'react';
import { Paper, Stack, Typography, useTheme } from '@mui/material';

const UploadHeader = ({
  selectedFiles,
  uploadCount = 0,
  errorCount = 0,
  validCount = 0,
}) => {
  const theme = useTheme();

  if (selectedFiles?.length === 0) {
    return <div></div>;
  } else {
    return (
      <Stack direction={'column'}>
        <Paper
          elevation={1}
          sx={{ mt: 1, p: 0.5, backgroundColor: theme.palette.grey[400] }}
        >
          <Stack direction={'row'} spacing={1} justifyContent='space-around'>
            <Typography
              variant='caption'
              color={theme.palette.primary.dark}
              sx={{ fontWeight: 'bold' }}
            >
              Selected: {selectedFiles?.length}
            </Typography>

            <Typography
              variant='caption'
              color={theme.palette.success.main}
              sx={{ fontWeight: 'bold' }}
            >
              Valid: {validCount}
            </Typography>

            <Typography
              variant='caption'
              color={theme.palette.warning.main}
              sx={{ fontWeight: 'bold' }}
            >
              Uploaded: {uploadCount} of {selectedFiles?.length}
            </Typography>

            <Typography
              variant='caption'
              color={theme.palette.error.main}
              sx={{ fontWeight: 'bold' }}
            >
              Errors: {errorCount} of {selectedFiles?.length}
            </Typography>
          </Stack>
        </Paper>
      </Stack>
    );
  }
};

export default UploadHeader;
