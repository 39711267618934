import React, { useContext, useState } from 'react';
import {
  Badge,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import ProjectUpdateModal from './ProjectUpdateModal';
import ProjectDeleteModal from './ProjectDeleteModal';
import { DataContext } from '../../../../shared/context/data-context';
import { overflowString } from '../../../../utils/helpers';

const ProjectItem = ({
  userId,
  project,
  handleSnackbarUpdate,
  handleProjectUpdate,
  handleProjectDelete,
}) => {
  const theme = useTheme();
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
      color: theme.palette.grey[700],
      backgroundColor: theme.palette.grey[400],
    },
  });

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Card elevation={4}>
        <CardHeader
          title={
            <Tooltip title={project.name}>
              <Typography fontWeight={700}>
                {overflowString(project.name)}
              </Typography>
            </Tooltip>
          }
          subheader={
            <Typography variant='body2'>
              {new Date(project.created).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </Typography>
          }
          action={
            <Tooltip title='Edit Project Name/ Description'>
              <IconButton
                aria-label='edit'
                onClick={() => setIsUpdateModalOpen(!isUpdateModalOpen)}
              >
                <EditIcon color='primary' fontSize='small' />
              </IconButton>
            </Tooltip>
          }
          sx={{ backgroundColor: theme.palette.grey[200] }}
        />
        <CardContent
          sx={{
            height: 120,
            // background: `linear-gradient(${theme.palette.grey[100]}, #e3f2fd)`,
            background: theme.palette.grey[100],
          }}
        >
          <Stack direction={'column'}>
            <Typography
              textAlign='center'
              variant='body2'
              color='text.secondary'
            >
              {project.description}
            </Typography>
            <br />
            <Typography
              textAlign='center'
              variant='caption'
              color='text.secondary'
            >
              {project.status}
            </Typography>
            <br />
            {project?.collaborators?.length > 0 && (
              <Chip
                label={`Shared with ${project?.collaborators?.length} user(s)`}
                size='small'
                variant='outlined'
                color='warning'
              />
            )}
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <Tooltip title='Manage Corpus Content'>
            <IconButton
              aria-label='upload'
              onClick={() => {
                dataContext.storeProject(project);
                navigate(`/files/${project.id}`);
              }}
            >
              <StyledBadge
                badgeContent={project.files.length}
                // sx={{
                //   '& .MuiBadge-badge': {
                //     color: 'white',
                //     backgroundColor: theme.palette.primary.light,
                //   },
                // }}
              >
                <CloudUploadIcon color='primary' fontSize='small' />
              </StyledBadge>
            </IconButton>
          </Tooltip>
          <Tooltip title='Manage Module Outlines'>
            <IconButton
              aria-label='outline'
              onClick={() => {
                dataContext.storeProject(project);
                navigate(`/outlines/${project.id}`);
              }}
            >
              <StyledBadge badgeContent={project.outlines.length}>
                <EditNoteIcon color='primary' fontSize='small' />
              </StyledBadge>
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              project.files.length === 0 || project.outlines.length === 0
                ? 'Upload documents & create an outline to generate content'
                : 'Generate Content'
            }
          >
            <span>
              <IconButton
                aria-label='generate'
                disabled={
                  project.files.length === 0 || project.outlines.length === 0
                }
                onClick={() => {
                  dataContext.storeProject(project);
                  navigate(`/generate/${project.id}`);
                }}
              >
                <AutoAwesomeIcon
                  fontSize='small'
                  color={
                    project.files.length === 0 || project.outlines.length === 0
                      ? ''
                      : 'primary'
                  }
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            title={
              project?.jobs?.length === 0
                ? 'No generated content available to download'
                : 'Download Generated Content'
            }
          >
            <span>
              <IconButton
                aria-label='download'
                disabled={(project?.jobs?.length || 0) === 0 ? true : false}
                onClick={() => {
                  dataContext.storeProject(project);
                  navigate(`/content/${project.id}`);
                }}
              >
                <StyledBadge badgeContent={project.jobs.length}>
                  <DownloadIcon
                    fontSize='small'
                    color={(project?.jobs?.length || 0) === 0 ? '' : 'primary'}
                  />
                </StyledBadge>
              </IconButton>
            </span>
          </Tooltip>

          {userId === project.creator ? (
            <>
              <Tooltip title='Collaborate'>
                <IconButton
                  aria-label='collaborate'
                  onClick={() => {
                    dataContext.storeProject(project);
                    navigate(`/collab/${project.id}`);
                  }}
                >
                  <StyledBadge badgeContent={project.collaborators.length}>
                    <GroupAddIcon fontSize='small' color='primary' />
                  </StyledBadge>
                </IconButton>
              </Tooltip>
              <Tooltip title='Delete Project'>
                <IconButton
                  aria-label='delete'
                  onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                >
                  <DeleteIcon
                    sx={{ color: theme.palette.error.main }}
                    fontSize='small'
                  />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <Chip label='Shared' size='small' />
          )}
        </CardActions>
      </Card>

      <ProjectUpdateModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        project={project}
        handleSnackbarUpdate={handleSnackbarUpdate}
        handleProjectUpdate={handleProjectUpdate}
      />

      <ProjectDeleteModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        project={project}
        handleSnackbarUpdate={handleSnackbarUpdate}
        handleProjectDelete={handleProjectDelete}
      />
    </Grid>
  );
};

export default ProjectItem;
