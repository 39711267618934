import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const item = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

const number = {
  fontSize: 24,
  fontFamily: "default",
  color: "secondary",
  fontWeight: "bold",
};

const How = () => {
  return (
    <React.Fragment>
      <Box
        component="section"
        sx={{
          display: "flex",
          overflow: "hidden",
          bgcolor: "#eceff1",
          boxShadow: 3,
        }}
      >
        <Container
          sx={{
            mb: 10,
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src="/static/theme/appCurvyLines.png"
            alt="curvy lines"
            sx={{
              pointerEvents: "none",
              position: "absolute",
              top: -360,
            }}
          />
          <Typography
            variant="h4"
            marked="center"
            component="h2"
            sx={{ mt: 5, mb: 3 }}
          >
            How it works
          </Typography>
          <div>
            <Grid container spacing={5}>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box sx={number}>1.</Box>
                  <Typography variant="h5" align="center">
                    Upload your documents
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box sx={number}>2.</Box>
                  <Typography variant="h5" align="center">
                    Define outlines for modules
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={item}>
                  <Box sx={number}>3.</Box>
                  <Typography variant="h5" align="center">
                    Generate content
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
          <Button
            color="secondary"
            size="large"
            variant="contained"
            sx={{ mt: 8 }}
            component={Link}
            to="/signup"
          >
            Get started
          </Button>
        </Container>
      </Box>
    </React.Fragment>
  );
};

export default How;
