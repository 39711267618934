import {
  LinearProgress,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { shortenedString } from './UploadUtils';

const File = ({ file, uploadProgress }) => {
  const theme = useTheme();

  return (
    <Stack
      direction={'column'}
      spacing={0}
      height={120}
      sx={{ overflow: 'auto', mt: 2 }}
    >
      <Paper
        elevation={1}
        sx={{ p: 1, backgroundColor: theme.palette.grey[400] }}
      >
        <Stack
          direction={'row'}
          spacing={4}
          justifyContent='space-around'
          sx={{ mb: 1 }}
        >
          <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
            {shortenedString(file.name)}
          </Typography>

          <Typography variant='caption'>{file.type}</Typography>

          <Typography variant='caption' textAlign={'right'}>
            {(file.size / 1024).toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{' '}
            Kb
          </Typography>
        </Stack>

        <Stack direction={'column'} height={2}>
          <Typography variant='caption'>
            <LinearProgress
              variant='determinate'
              value={uploadProgress[file.name]?.pctCompleted || 0}
              color='primary'
            />
          </Typography>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default File;
