import React from 'react';
import Template from './components/Template';
import { Typography } from '@mui/material';

const Page404 = () => {
  return (
    <Template title='404'>
      <Typography>Page not found</Typography>
    </Template>
  );
};

export default Page404;
