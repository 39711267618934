import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  MRT_EditActionButtons,
  createRow,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import {
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
  Tooltip,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

const MRTRenderer = ({
  data,
  setData,
  saveOutline,
  autoSave,
  setAutoSave,
  toggleTextParserVis,
  enableSave,
}) => {
  // const [data, setData] = useState(outline);
  const [validationErrors, setValidationErrors] = useState({});
  const [isPendingSave, setIsPendingSave] = useState(false);

  useEffect(() => {
    setIsPendingSave(enableSave);
    // setAutoSave(false); // uncomment if OutlinePageMRT autoSave default useState is false
  }, [enableSave]); // , setAutoSave

  const validateValue = useCallback((value) => !!value, []);

  const validateOutlineItem = useCallback(
    (item) => {
      return {
        section: !validateValue(item.section) ? 'Section is required' : '',
        title: !validateValue(item.title) ? 'Title is required' : '',
        prompt: !validateValue(item.prompt) ? 'Prompt is required' : '',
      };
    },
    [validateValue]
  );

  //simple column definitions pointing to flat data
  const columns = useMemo(
    () => [
      {
        accessorFn: (originalRow) => parseInt(originalRow.seq), //alternate way
        id: 'seq', //id required if you use accessorFn instead of accessorKey
        header: 'Seq',
        // Header: <i style={{ color: 'red' }}>#</i>, //optional custom markup
        Cell: ({ cell }) => <i>{cell.getValue().toLocaleString()}</i>, //optional custom cell render
        // grow: false,
        size: 30,
        enableEditing: false,
      },
      {
        accessorKey: 'section', //simple recommended way to define a column
        header: 'Section',
        // muiTableHeadCellProps: { style: { color: 'green' } }, //custom props
        enableHiding: false, //disable a feature for this column
        size: 70,
        editVariant: 'select',
        required: true,
        editSelectOptions: ['topic', 'subtopic', 'body', 'definition'],
        error: !!validationErrors?.section,
        helperText: validationErrors?.section,
        onFocus: () =>
          setValidationErrors({
            ...validationErrors,
            section: undefined,
          }),
      },
      {
        accessorKey: 'title', //simple recommended way to define a column
        header: 'Title',
        enableHiding: false, //disable a feature for this column
        // size: 200,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: 'text',
          required: true,
          multiline: true,
          maxRows: 4,
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
        }),
      },
      {
        accessorKey: 'prompt', //simple recommended way to define a column
        header: 'Prompt',
        enableHiding: false, //disable a feature for this column
        // size: 350,
        muiEditTextFieldProps: ({ cell, row }) => ({
          type: 'text',
          required: true,
          multiline: true,
          maxRows: 8,
          error: !!validationErrors?.prompt,
          helperText: validationErrors?.prompt,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              prompt: undefined,
            }),
        }),
      },
      {
        accessorKey: 'url', //simple recommended way to define a column
        header: 'URL',
        // size: 100,
      },
    ],
    [validationErrors]
  );

  const getNextSeq = () => {
    let maxId = 0;
    if (data && data.length > 0) {
      maxId = Math.max(...data.map((item) => item.seq));
    }
    return maxId + 1;
  };

  const getNextSection = () => {
    let nextSection = 'topic';

    if (data && data.length > 0) {
      const lastObject = data[data.length - 1];
      const lastSection = lastObject.section;
      if (lastSection === 'topic') {
        nextSection = 'subtopic';
      } else if (lastSection === 'subtopic') {
        nextSection = 'body';
      } else if (lastSection === 'body') {
        nextSection = 'body';
      } else if (lastSection === 'definition') {
        nextSection = 'definition';
      }
    }

    return nextSection;
  };

  // eslint-disable-next-line
  const copyObjectsByAttributes = () => {
    // eslint-disable-next-line
    const attributesToCopy = ['seq', 'section', 'title', 'prompt', 'url'];
    const rowModels = table.getPrePaginationRowModel().rows;
    const newArray = rowModels.map((row) => {
      const originalValues = row.original;
      const newObj = {};
      Object.keys(originalValues).forEach(
        (key) => (newObj[key] = originalValues[key])
      );
      return newObj;
    });
    console.log(newArray);
  };

  const handleSave = async (updatedData) => {
    setIsPendingSave(false);
    // console.log(updatedData);
    await saveOutline(updatedData);
  };

  // CREATE
  const handleCreate = async ({ values, table }) => {
    const newValidationErrors = validateOutlineItem(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setValidationErrors({});

    // await createUser(values);

    const updatedData = [...data, values];
    if (autoSave) {
      await handleSave(updatedData);
    } else {
      setIsPendingSave(true);
      setData(updatedData);
    }

    table.setCreatingRow(null); //exit creating mode
  };

  // UPDATE
  const handleEdit = async ({ values, table }) => {
    const newValidationErrors = validateOutlineItem(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    const updatedData = data.map((item) => {
      if (item.seq === values.seq) {
        return values;
      } else {
        return item;
      }
    });

    if (autoSave) {
      await handleSave(updatedData);
    } else {
      setIsPendingSave(true);
      setData(updatedData);
    }

    table.setEditingRow(null); //exit editing mode
  };

  // DELETE
  const handleDelete = async (row) => {
    if (window.confirm('Delete the item?')) {
      const updatedData = data.filter((item) => row.original.seq !== item.seq);
      if (autoSave) {
        await handleSave(updatedData);
      } else {
        setIsPendingSave(true);
        setData(updatedData);
      }
    }
  };

  // useEffect(() => {
  //   console.log(autoSave);
  // }, [autoSave]);

  //pass table options to useMaterialReactTable
  const table = useMaterialReactTable({
    columns,
    data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableSorting: false,
    editDisplayMode: 'modal', // ('modal', 'cell', 'table', and 'custom' are also available)
    enableRowActions: true,
    positionActionsColumn: 'last',
    enableEditing: true,
    enableColumnFilterModes: false,
    enableRowOrdering: true,
    enableGrouping: false,
    enableFilters: false,
    enableColumnActions: false,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableFullScreenToggle: false,

    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );
          if (autoSave) {
            handleSave([...data]);
          } else {
            setIsPendingSave(true);
            setData([...data]);
          }
        }
        // console.log(data);
      },
    }),
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreate,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant='h5'>Add Outline Item</DialogTitle>

        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>

        <DialogActions>
          {/* eslint-disable-next-line */}
          <MRT_EditActionButtons variant='text' table={table} row={row} />
        </DialogActions>
      </>
    ),
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleEdit,
    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant='h5'>Edit Item</DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
        >
          {internalEditComponents} {/* or render custom edit components here */}
        </DialogContent>
        <DialogActions>
          {/* eslint-disable-next-line */}
          <MRT_EditActionButtons variant='text' table={table} row={row} />
        </DialogActions>
      </>
    ),
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '0.25rem' }}>
        <Tooltip title='Edit'>
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton color='error' onClick={() => handleDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),

    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: '0.25rem' }}>
        <FormControlLabel
          control={
            <Switch
              checked={autoSave}
              color='primary'
              onChange={(e) => setAutoSave(e.target.checked)}
            />
          }
          label='Auto save'
        />
        <Tooltip title='Import Data'>
          <IconButton onClick={toggleTextParserVis}>
            <ContentPasteIcon color='primary' />
          </IconButton>
        </Tooltip>
        <Button
          variant='outlined'
          onClick={() => {
            // table.setCreatingRow(true);
            table.setCreatingRow(
              createRow(table, { seq: getNextSeq(), section: getNextSection() })
            );
          }}
          startIcon={<AddIcon />}
        >
          Add Outline Item
        </Button>
      </Box>
    ),
    enableBottomToolbar: !autoSave,
    renderBottomToolbarCustomActions: () => (
      <Grid container justifyContent='flex-end'>
        <Button
          disabled={!isPendingSave}
          sx={{ ml: 2 }}
          variant='contained'
          onClick={() => handleSave([...data])}
          startIcon={<SaveIcon />}
        >
          Save Outline
        </Button>
      </Grid>
    ),

    initialState: { columnVisibility: { seq: false, url: false } },
  });

  return (
    <Container sx={{ my: 2 }}>
      <MaterialReactTable table={table} />
    </Container>
  );
};

export default MRTRenderer;
