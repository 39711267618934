import React, { useEffect, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';

const RefreshButton = ({ callback, blockTimeSecs = 10 }) => {
  const [disabled, setDisabled] = useState(false);
  const [progress, setProgress] = useState(0);

  const CircularProgressWithLabel = ({ value }) => {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant='determinate' value={value} size={20} />
        <Box
          sx={{
            top: 1,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='caption' component='div' color='text.secondary'>
            {`${blockTimeSecs - Math.round(value / 10)}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    let timer;
    if (disabled) {
      timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100
            ? 0
            : prevProgress + Math.round(100 / blockTimeSecs)
        );
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [disabled, blockTimeSecs]);

  const handleClick = () => {
    setDisabled(true);
    setProgress(0);

    setTimeout(() => {
      setDisabled(false);
    }, blockTimeSecs * 1000); // 10 seconds in milliseconds

    callback();
  };

  return (
    <IconButton color='primary' onClick={handleClick} disabled={disabled}>
      {disabled ? (
        <CircularProgressWithLabel color='inherit' value={progress} />
      ) : (
        <RefreshIcon />
      )}
    </IconButton>
  );
};

export default RefreshButton;
