import React, { useCallback, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Alert, IconButton, InputAdornment, useTheme } from '@mui/material';

import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '../../shared/components/ui/LoadingButton';

import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import DomainIcon from '@mui/icons-material/Domain';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const signupValidationSchema = Yup.object().shape({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  affiliation: Yup.string().required('Required'),
  email: Yup.string().email('Invalid format').required('Required'),
  password: Yup.string()
    .min(8, 'Must be atleast 8 characters')
    .required('Required'),
});

const SignUp = () => {
  const theme = useTheme();
  const [resMessage, setResMessage] = useState();
  const [resErrorMessage, setResErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstname: '',
      lastname: '',
      affiliation: '',
      email: '',
      password: '',
    },
    resolver: yupResolver(signupValidationSchema),
  });

  const handleSignup = useCallback(
    async (formData) => {
      // const data = new FormData(event.currentTarget);
      // console.log(formData);
      setResErrorMessage(null);
      setIsLoading(true);

      // const formData = new FormData(event.currentTarget);

      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/users/signup`,
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        // console.log(data);
        setResMessage(`Account verification email sent to ${data.email}`);
        reset();
      } catch (error) {
        setResErrorMessage(
          error.response?.data.message || 'Unable to signup. Please try again.'
        );
      }
      setIsLoading(false);
    },
    [reset]
  );

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          // marginTop: '6rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // minHeight: '80vh',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <EditNoteIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Sign up
        </Typography>
        <Box
          component='form'
          noValidate
          onSubmit={handleSubmit(handleSignup)}
          sx={{ mt: 3 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('firstname')}
                autoComplete='given-name'
                name='firstname'
                required
                fullWidth
                id='firstname'
                label='First Name'
                autoFocus
                variant='filled'
                {...(errors.firstname && {
                  error: true,
                  helperText: errors.firstname.message,
                })}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                {...register('lastname')}
                required
                fullWidth
                id='lastname'
                label='Last Name'
                name='lastname'
                autoComplete='family-name'
                variant='filled'
                {...(errors.lastname && {
                  error: true,
                  helperText: errors.lastname.message,
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('affiliation')}
                required
                fullWidth
                id='affiliation'
                label='Affiliation'
                name='affiliation'
                variant='filled'
                {...(errors.affiliation && {
                  error: true,
                  helperText: errors.affiliation.message,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <DomainIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('email')}
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                variant='filled'
                {...(errors.email && {
                  error: true,
                  helperText: errors.email.message,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                {...register('password')}
                required
                fullWidth
                name='password'
                label='Password'
                type={showPassword ? 'text' : 'password'}
                id='password'
                autoComplete='new-password'
                variant='filled'
                {...(errors.password && {
                  error: true,
                  helperText: errors.password.message,
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PasswordIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                type='submit'
                fullWidth
                variant='contained'
                // sx={{ mt: 2, mb: 2 }}
                isLoading={isLoading}
              >
                Sign Up
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              {resMessage && (
                <Alert severity='success' sx={{ mb: 1 }}>
                  {resMessage}
                </Alert>
              )}

              {resErrorMessage && (
                <Alert severity='error' sx={{ mb: 1 }}>
                  {resErrorMessage}
                </Alert>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <Grid
              item
              component={Link}
              to='/login'
              typography={theme.typography.body2}
            >
              Already have an account? Sign in
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default SignUp;
