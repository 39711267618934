import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SummarizeIcon from '@mui/icons-material/Summarize';
import GradingIcon from '@mui/icons-material/Grading';

const item = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  px: 5,
};

const Description = () => {
  return (
    <Box
      component='section'
      sx={{
        display: 'flex',
        overflow: 'hidden',
        bgcolor: '#eceff1',
        boxShadow: 3,
      }}
    >
      <Container sx={{ mt: 10, mb: 10, display: 'flex', position: 'relative' }}>
        <Box
          component='img'
          src='/static/theme/appCurvyLines.png'
          alt='curvy lines'
          sx={{ pointerEvents: 'none', position: 'absolute', top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <AutoStoriesIcon sx={{ fontSize: '3.5em' }} />

              <Typography variant='h5' sx={{ my: 4 }}>
                Curate your Corpus
              </Typography>
              <Typography>
                Drop in your curated set of PDF’s, recordings, or docs and
                develop your outline.  Go from a wide array to one voice, one reference set.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <SummarizeIcon sx={{ fontSize: '3.5em' }} />

              <Typography variant='h5' sx={{ my: 4 }}>
                Generate Course Tools
              </Typography>
              <Typography>
                Choose the support materials you’d to generate such as course readings, slides,
                quizzes, or live cases.  Monitor progress and adapt to suit your needs
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <GradingIcon sx={{ fontSize: '3.5em' }} />

              <Typography variant='h5' sx={{ my: 4 }}>
                Automatic Assessment
              </Typography>
              <Typography>
                Give more complete feedback faster, with our AI agents.  Share part or all of the feedback and scoring with your students
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Description;
