import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import CopyToClipboard from '../../../shared/components/ui/CopyToClipboard';

const StyledButton = styled(Button)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.dark : 'default',
  color: selected ? theme.palette.primary.contrastText : 'default',
}));

const tableStyling = {
  padding: '0px 0px',
  width: 30,
};

const Alerts = ({ authToken }) => {
  const [daysPast, setDaysPast] = useState(1);
  const [selectedButton, setSelectedButton] = useState('b1');
  const [alerts, setAlerts] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/alerts?days=${daysPast}`
        );
        setAlerts(data.alerts);
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      }
    };

    fetchAlerts();
  }, [authToken, daysPast]);

  const handleButtonClick = (id, range) => {
    setSelectedButton(id);
    setDaysPast(range);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <>
          <Typography variant='body1' fontWeight={700} sx={{ mb: 2 }}>
            Alerts
          </Typography>

          <ButtonGroup
            variant='contained'
            size='small'
            aria-label='Days range button group'
          >
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b1'}
              onClick={() => handleButtonClick('b1', 1)}
            >
              24 hours
            </StyledButton>
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b2'}
              onClick={() => handleButtonClick('b2', 7)}
            >
              1 Week
            </StyledButton>
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b3'}
              onClick={() => handleButtonClick('b3', 30)}
            >
              1 Month
            </StyledButton>
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b4'}
              onClick={() => handleButtonClick('b4', 365)}
            >
              1 Year
            </StyledButton>
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b5'}
              onClick={() => handleButtonClick('b5', -1)}
            >
              All
            </StyledButton>
          </ButtonGroup>
        </>
      </Box>

      {error ? (
        <Alert severity='warning'>{error}</Alert>
      ) : alerts?.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 'max-content',
            padding: 1,
          }}
        >
          <Table size='small' sx={{ tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableStyling }}>#</TableCell>
                <TableCell sx={{ ...tableStyling }}>Origin</TableCell>
                <TableCell sx={{ ...tableStyling }}>Job</TableCell>
                <TableCell sx={{ ...tableStyling }}>Process</TableCell>
                <TableCell sx={{ ...tableStyling }}>message</TableCell>
                <TableCell sx={{ ...tableStyling }}>Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alerts?.map((alert, index) => (
                <TableRow key={index}>
                  <TableCell>{alerts.length - index}</TableCell>
                  <TableCell>{alert.origin}</TableCell>
                  <TableCell>
                    <CopyToClipboard text={alert.jobId} />
                  </TableCell>
                  <TableCell>{alert.process}</TableCell>
                  <TableCell>{alert.message}</TableCell>
                  <TableCell>
                    {new Date(alert.created).toLocaleString()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography textAlign='center'>
          No records available for the selected range
        </Typography>
      )}
    </>
  );
};

export default Alerts;
