import React, { useEffect, useRef, useState } from 'react';
import { TextField, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const EditableTextField = ({
  label,
  value,
  onSave,
  saveBlank = false,
  validationFn = (value) => true,
  validationMessage = '',
}) => {
  const textRef = useRef(null);
  const [editing, setEditing] = useState(false);
  const [tempValue, setTempValue] = useState(value);
  const [errorText, setErrorText] = useState();

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  const handleEditClick = () => {
    setEditing(true);
    if (textRef?.current) {
      textRef.current.focus();
    }
  };

  const handleSaveClick = () => {
    setErrorText();

    if (tempValue === value) {
      // If the values are the same, skip update
      setEditing(false);
      return;
    }

    if (!saveBlank && !tempValue) {
      setErrorText('Required');
      return;
    }

    if (!validationFn(tempValue)) {
      // what if validation fails?
      setErrorText(validationMessage);
      return;
    }

    onSave(label, tempValue);
    setEditing(false);
  };

  const handleCancelClick = () => {
    setTempValue(value);
    setEditing(false);
    setErrorText();
  };

  return (
    <>
      {editing ? (
        <>
          <TextField
            label={label}
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            variant="filled"
            sx={{ width: '79%' }}
            error={errorText ? true : false}
            helperText={errorText}
          />
          <Tooltip title="Save" placement="bottom">
            <IconButton onClick={handleSaveClick}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cancel" placement="right">
            <IconButton onClick={handleCancelClick}>
              <CancelIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <TextField
            label={label}
            value={value}
            inputRef={textRef}
            variant="filled"
            fullWidth
            sx={{ width: '89%' }}
          />
          <Tooltip title="Edit" placement="right">
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};

export default EditableTextField;

// startAdornment={
//   <InputAdornment position="start">
//     <AccountCircle />
//   </InputAdornment>
// }
