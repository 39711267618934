import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import UserItem from './UserItem';

const UserList = ({
  users = [],
  project,
  handleAddRemoveActions,
  showEmptyMessage = false,
}) => {
  return users?.length > 0 ? (
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Domain</TableCell>
            <TableCell>Affiliation</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user, index) => (
            <UserItem
              key={user.id}
              user={user}
              index={index + 1}
              project={project}
              handleAddRemoveActions={handleAddRemoveActions}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : showEmptyMessage ? (
    <Typography textAlign={'center'}>
      To collaborate with others, find a user via the second tab on this page
      and add them to your project.
    </Typography>
  ) : null;
};

export default UserList;
