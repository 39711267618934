import React, { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const ContentDownloadOptions = ({ job, handleFileDownload }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (docPath) => {
    setAnchorEl(null);

    if (docPath) {
      const bucket = job.output.bucket;
      const doc = job.output[docPath];
      handleFileDownload(bucket, doc);
    }
  };

  return (
    <div>
      <IconButton aria-label='download options' onClick={handleClick}>
        <DownloadIcon color='primary' />
      </IconButton>

      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleClose('docxPath')}>
          <ListItemIcon>
            <DownloadIcon fontSize='small' color='primary' />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>
            Word Document
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleClose('pptxPath')}>
          <ListItemIcon>
            <DownloadIcon fontSize='small' color='primary' />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>
            Powerpoint Slides
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleClose('pdfPath')}>
          <ListItemIcon>
            <DownloadIcon fontSize='small' color='primary' />
          </ListItemIcon>
          <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>
            PDF Document
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ContentDownloadOptions;
