import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Grid,
  Typography,
  styled,
} from '@mui/material';
import SimpleCard from '../../../shared/components/ui/SimpleCard';
import Tile from './Tile';
import { capitalizeFirstLetter } from '../../../utils/helpers';

const StyledButton = styled(Button)(({ theme, selected }) => ({
  backgroundColor: selected ? theme.palette.primary.dark : 'default',
  color: selected ? theme.palette.primary.contrastText : 'default',
}));

const Files = ({ authToken }) => {
  const [daysPast, setDaysPast] = useState(1);
  const [selectedButton, setSelectedButton] = useState('b1');
  const [fileStats, setFileStats] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchFileStats = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/file-stats?days=${daysPast}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        // sort
        data.stats.sort((a, b) => {
          if (a.status < b.status) return -1;
          if (a.status > b.status) return 1;
          return 0;
        });

        setFileStats(data.stats);
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      }
    };

    fetchFileStats();
  }, [authToken, daysPast]);

  const handleButtonClick = (id, range) => {
    setSelectedButton(id);
    setDaysPast(range);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <>
          <Typography variant='body1' fontWeight={700} sx={{ mb: 2 }}>
            File Stats
          </Typography>

          <ButtonGroup
            variant='contained'
            size='small'
            aria-label='Days range button group'
          >
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b1'}
              onClick={() => handleButtonClick('b1', 1)}
            >
              24 hours
            </StyledButton>
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b2'}
              onClick={() => handleButtonClick('b2', 7)}
            >
              1 Week
            </StyledButton>
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b3'}
              onClick={() => handleButtonClick('b3', 30)}
            >
              1 Month
            </StyledButton>
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b4'}
              onClick={() => handleButtonClick('b4', 365)}
            >
              1 Year
            </StyledButton>
            <StyledButton
              sx={{ width: 120 }}
              selected={selectedButton === 'b5'}
              onClick={() => handleButtonClick('b5', -1)}
            >
              All
            </StyledButton>
          </ButtonGroup>
        </>
      </Box>

      {error ? (
        <Alert severity='warning'>{error}</Alert>
      ) : fileStats.length > 0 ? (
        <Grid container spacing={2}>
          {fileStats?.map((item, idx) => {
            return (
              <Grid key={idx} item xs={12} md={3}>
                <SimpleCard height={90} elevation={4}>
                  <Tile caption={capitalizeFirstLetter(item.status)}>
                    {item.count}
                  </Tile>
                </SimpleCard>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <Typography textAlign='center'>
          No records for the selected range
        </Typography>
      )}
    </>
  );
};

export default Files;
