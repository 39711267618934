import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { DataContext } from '../../shared/context/data-context';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ProjectStepper from '../../shared/components/ui/ProjectStepper';
import ContentList from './components/ContentList';
import OutlineTable from './components/OutlineTable';
import GenContentDetails from './components/GenContentDetails';
import ContentDownloadOptions from './components/ContentDownloadOptions';
import RefreshButton from '../../shared/components/ui/RefreshButton';

const ContentLandingPage = () => {
  const dataContext = useContext(DataContext);
  const theme = useTheme();
  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [job, setJob] = useState();
  const [content, setContent] = useState();
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [jobQueueCount, setJobQueueCount] = useState(0);

  const fetchJobs = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/jobs/project/${project.id}`
      );
      setJobs(data.jobs);
      setSnackbarMessage('Content loaded!');
      setSnackbarOpen(true);
    } catch (error) {
      setJobs([]);
      setSnackbarMessage(
        error.response?.data.message || 'Unable to retrieve jobs.'
      );
      setSnackbarOpen(true);
    }

    // queued job count
    // try {
    //   const { data } = await axios.get(
    //     `${process.env.REACT_APP_API_URL}/jobs/queue/count`
    //   );
    //   setJobQueueCount(data.count);
    // } catch (error) {
    //   setJobQueueCount(0);
    //   setSnackbarMessage(
    //     error.response?.data.message || 'Unable to retrieve job count.'
    //   );
    //   setSnackbarOpen(true);
    // }

    setIsLoading(false);
  }, [project?.id]);

  useEffect(() => {
    const project = dataContext.getProject();
    setProject(project);
    // console.log(project);
    if (project.id) {
      fetchJobs();
    }
  }, [dataContext, fetchJobs]);

  const refreshJobs = () => {
    fetchJobs();
  };

  const handleItemSelection = (jobId) => {
    const fetchJob = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/jobs/${jobId}`
        );
        setJob(data.job);
        setContent();
        setSnackbarMessage('Content item loaded!');
        setSnackbarOpen(true);
      } catch (error) {
        setJobs([]);
        setSnackbarMessage(
          error.response?.data.message || 'Unable to retrieve content item.'
        );
        setSnackbarOpen(true);
      }
      setIsLoading(false);
    };

    fetchJob();
  };

  const handleOutlineSelection = (index) => {
    setContent(job.content.generated[index]);
  };

  const handleFileDownload = (bucket, filePath) => {
    const fetchSignedUrl = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/cloud/signedURL?folder=${bucket}&filePath=${filePath}`
        );

        if (response.data.signedUrl[0]) {
          window.open(response.data.signedUrl[0], '_blank');
        }
      } catch (error) {
        setSnackbarMessage('Error fetching document');
        setSnackbarOpen(true);
      }
      setIsLoading(false);
    };

    fetchSignedUrl();
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      <ProjectStepper project={project} activeStep={3} />

      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2} justifyContent='center'>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={4}
              sx={{
                p: 2,
                minHeight: '650px',
                maxHeight: '650px',
                overflow: 'auto',
              }}
            >
              <Grid
                container
                spacing={0}
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid>&nbsp;</Grid>
                <Grid>
                  <Typography
                    variant='body1'
                    fontWeight={700}
                    color='primary'
                    fontSize='inherit'
                  >
                    Content items ({jobs?.length})
                  </Typography>
                </Grid>
                <Grid>
                  <RefreshButton callback={refreshJobs} />
                </Grid>
              </Grid>

              <ContentList
                jobs={jobs}
                handleItemSelection={handleItemSelection}
                // jobQueueCount={jobQueueCount}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  elevation={4}
                  sx={{
                    p: 2,
                    minHeight: '300px',
                    maxHeight: '300px',
                    overflow: 'auto',
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item xs={4}>
                      &nbsp;
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        textAlign='center'
                        variant='body1'
                        fontWeight={700}
                        color='primary'
                        fontSize='inherit'
                      >
                        Outline
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display='flex' justifyContent='flex-end'>
                        {job?.output && (
                          <ContentDownloadOptions
                            job={job}
                            handleFileDownload={handleFileDownload}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* {job?.output && (
                    <Typography
                      variant='h6'
                      color='secondary'
                      textAlign='center'
                    >
                      {job?.output?.docxPath && (
                        <Tooltip title='Download generated content'>

                          <BsFileWordFill />
                        </Tooltip>
                      )}
                      {job?.output?.pptxPath && <BsFilePptFill size={24} />}
                    </Typography>
                  )} */}

                  <OutlineTable
                    job={job}
                    handleOutlineSelection={handleOutlineSelection}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Paper
                  elevation={4}
                  sx={{
                    p: 2,
                    minHeight: '300px',
                    overflow: 'auto',
                  }}
                >
                  <Typography
                    textAlign='center'
                    variant='body1'
                    fontWeight={700}
                    color='primary'
                    fontSize='inherit'
                  >
                    Generated Content
                  </Typography>
                  <GenContentDetails content={content} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setSnackbarOpen(false)}
          >
            <CancelRoundedIcon fontSize='small' />
          </IconButton>
        }
      />
    </>
  );
};

export default ContentLandingPage;
