import React from 'react';
import { Avatar, Grid, Paper, Typography } from '@mui/material';

const IntroCard = ({ alt, src, children }) => {
  return (
    <Paper elevation={2} sx={{ mt: '1rem', px: '1rem', py: '1rem' }}>
      <Grid container alignItems='center'>
        <Grid item xs={3} sm={2}>
          <Avatar alt={alt} src={src} sx={{ width: 80, height: 80 }} />
        </Grid>
        <Grid item xs={9} sm={10}>
          <Typography sx={{ lineHeight: '24px', py: 2 }} variant='smallerFont'>
            {children}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default IntroCard;
