import { Container, Typography } from '@mui/material';
import React from 'react';

const Categories = () => {
  return (
    <Container component='section' sx={{ pt: 8, pb: 8 }}>
      <Typography variant='h4' marked='center' align='center'>
        Your course, your presentations, your question banks, automated grading
      </Typography>
      <Typography sx={{ my: 3 }}>
        CorpusKey’s faculty developed custom GenAI agents allow you to easily
        generate your custom course with the material that you curate. Whether
        you need one small teaching module, or a full semester, you can generate
        the materials you need for your course with all the complements such as
        slide decks, test banks, and live cases.
      </Typography>
      <Typography sx={{ my: 3 }}>
        CorpusKey’s AI agents also take the burden out of assessments allowing faster more complete feedback for both long and short form responses.  Reduce interrater error and increase consistency, whether it's a class of 40 or 400.
      </Typography>
      <Typography sx={{ my: 3 }}>
        You know what you want to teach.  CorpusKey is designed to take instructors from a curated set of
        materials and an outline to synthesized readings with attribution,
        presentation decks, and many other support tools. Have the perfect book
        but need a question bank or slide deck? We do that too.
      </Typography>
      <Typography sx={{ my: 3 }}>
        Something happen in the news this morning and you’d like a couple quick
        slides related to your material? Our custom AI agents are here to do the
        work for you.
      </Typography>
    </Container>
  );
};

export default Categories;
