import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LoadingButton from '../../../shared/components/ui/LoadingButton';

const updateSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const OutlineUpdateModal = ({
  isOpen,
  setIsOpen,
  outline,
  handleSnackbarUpdate,
  handleOutlineUpdate,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { name: outline.name },
    resolver: yupResolver(updateSchema),
  });

  const handleEdit = React.useCallback(
    async (formData) => {
      // console.log(formData);
      setIsLoading(true);

      try {
        // eslint-disable-next-line
        const { data } = await axios.patch(
          `${process.env.REACT_APP_API_URL}/outlines/${outline.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        setIsLoading(false);
        handleOutlineUpdate(outline.id, formData.name);
        handleSnackbarUpdate('Outline updated.');
      } catch (error) {
        setIsLoading(false);
        handleSnackbarUpdate(
          error.response?.data.message ||
            'Unable to update outline. Please try again.'
        );
      }
      setIsOpen(false);
    },
    [handleOutlineUpdate, handleSnackbarUpdate, setIsOpen, outline.id]
  );

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby='outline-update'
      aria-describedby='update-modal'
    >
      <Box
        minWidth={theme.breakpoints.values.sm}
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.dark}`,
          borderRadius: '8px',
          boxShadow: 12,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card elevation={12} sx={{ width: '100%' }}>
          <CardHeader
            title='Edit Outline Name'
            action={
              <Tooltip title='Close'>
                <IconButton
                  aria-label='close'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <CancelRoundedIcon />
                </IconButton>
              </Tooltip>
            }
            sx={{ backgroundColor: theme.palette.primary.dark, color: 'white' }}
          />
          <Box
            component='form'
            onSubmit={handleSubmit(handleEdit)}
            noValidate
            sx={{ mt: 1 }}
          >
            <CardContent>
              <Typography
                textAlign='center'
                variant='body2'
                color='text.secondary'
              >
                Outline created:{' '}
                {new Date(outline.created).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </Typography>
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                spacing={2}
              >
                <Grid item>
                  <TextField
                    {...register('name')}
                    margin='normal'
                    required
                    fullWidth
                    id='name'
                    label='Outline Name'
                    name='name'
                    autoFocus
                    {...(errors.name && {
                      error: true,
                      helperText: errors.name.message,
                    })}
                    variant='filled'
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
              <Button onClick={() => reset()}>Reset</Button>
              <LoadingButton
                type='submit'
                isLoading={isLoading}
                variant='contained'
              >
                Update
              </LoadingButton>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default OutlineUpdateModal;
