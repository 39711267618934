import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import HelpCenterRoundedIcon from '@mui/icons-material/HelpCenterRounded';

const BottomHero = () => {
  return (
    <Container
      component='section'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        my: 9,
      }}
    >
      <HelpCenterRoundedIcon sx={{ fontSize: '4em' }} color='primary' />
      <Button
        sx={{
          border: '4px solid currentColor',
          borderRadius: 0,
          height: 'auto',
          py: 2,
          px: 5,
        }}
        component={Link}
        to='/help'
      >
        <Typography variant='h6' component='span'>
          Have Questions?
        </Typography>
      </Button>
    </Container>
  );
};

export default BottomHero;
