import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Divider,
  Icon,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import drawerImage from './appCurvyLines.png';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PaymentIcon from '@mui/icons-material/Payment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SendIcon from '@mui/icons-material/Send';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Logout from '@mui/icons-material/Logout';

import { useNavigate } from 'react-router-dom';

const NavDrawer = ({ auth, navLinks, setTabIndex }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [visible, setVisible] = useState(false);

  const handleClick = (item) => {
    setVisible(!visible);
    if (!!item.idx) {
      setTabIndex(item.idx);
    }
    if (!!item.url) {
      if (item.url === '/logout') {
        auth.logout();
      } else {
        navigate(item.url);
      }
    }
  };

  return (
    <React.Fragment>
      <Drawer
        open={visible}
        onClose={() => setVisible(!visible)}
        PaperProps={{
          sx: { mr: '2rem', backgroundImage: `url(${drawerImage})` },
        }}
        color='secondary'
      >
        <List>
          <ListItemButton
            onClick={() => handleClick({ label: 'Home', url: '/' })}
          >
            <VpnKeyIcon sx={{ color: 'text.secondary', mr: 2 }} />
            <Typography
              component='h6'
              variant='h6'
              align='center'
              color='text.primary'
            >
              CorpusKey
            </Typography>
          </ListItemButton>
          {navLinks.map((item, index) => {
            return (
              <ListItemButton key={index} onClick={() => handleClick(item)}>
                <ListItemIcon>
                  <Icon component={item.icon} fontSize='small' />
                </ListItemIcon>
                <ListItemText>{item.label}</ListItemText>
              </ListItemButton>
            );
          })}
          <Divider />
          {!auth.isLoggedIn && (
            <>
              <ListItemButton
                onClick={() => handleClick({ label: 'Login', url: '/login' })}
              >
                <ListItemIcon>
                  <LockOutlinedIcon />
                </ListItemIcon>
                <ListItemText>Login</ListItemText>
              </ListItemButton>
              <ListItemButton
                onClick={() => handleClick({ label: 'Signup', url: '/signup' })}
              >
                <ListItemIcon>
                  <EditNoteIcon />
                </ListItemIcon>
                <ListItemText>Sign Up</ListItemText>
              </ListItemButton>
            </>
          )}

          {auth.isLoggedIn && (
            <>
              <ListItemButton
                onClick={() =>
                  handleClick({ label: 'Dashboard', url: '/landing' })
                }
              >
                <ListItemIcon>
                  <DashboardIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
              </ListItemButton>

              <ListItemButton
                onClick={() =>
                  handleClick({ label: 'Payments', url: '/payments' })
                }
              >
                <ListItemIcon>
                  <PaymentIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Payments</ListItemText>
              </ListItemButton>

              <ListItemButton
                onClick={() =>
                  handleClick({ label: 'Settings', url: '/settings' })
                }
              >
                <ListItemIcon>
                  <ManageAccountsIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
              </ListItemButton>

              <ListItemButton
                onClick={() => handleClick({ label: 'Invite', url: '/invite' })}
              >
                <ListItemIcon>
                  <SendIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText>Invite a collaborator</ListItemText>
              </ListItemButton>

              {auth.user.su && (
                <ListItemButton
                  onClick={() => handleClick({ label: 'Admin', url: '/admin' })}
                >
                  <ListItemIcon>
                    <AdminPanelSettingsIcon
                      fontSize='small'
                      style={{ color: theme.palette.error.light }}
                    />
                  </ListItemIcon>
                  <ListItemText>Site administration</ListItemText>
                </ListItemButton>
              )}

              <Divider />

              <ListItemButton
                onClick={() => handleClick({ label: 'Logout', url: '/logout' })}
              >
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </ListItemButton>
            </>
          )}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: 'white', marginLeft: 'auto' }}
        onClick={() => setVisible(!visible)}
      >
        <MenuIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default NavDrawer;
