import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../shared/context/auth-context';
import { DataContext } from '../../shared/context/data-context';
import {
  Backdrop,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
  useTheme,
} from '@mui/material';
import { style } from '../../shared/styles/style';
import FMDetails from '../project/components/file/FMDetails';
import GenInputs from './components/GenInputs';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ProjectStepper from '../../shared/components/ui/ProjectStepper';
import OutlineSelection from './components/OutlineSelection';

const GeneratePage = () => {
  const auth = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState();
  const [populatedProject, setPopulatedProject] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [isPaymentMethodValid, setIsPaymentMethodValid] = useState(false);

  useEffect(() => {
    setProject(dataContext.getProject());
    // console.log(dataContext.getProject());

    // Get the project
    const fetchPopulatedProject = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/projects/p/${
            dataContext.getProject().id
          }?files=true&outlines=true&jobs=true`
        );
        setPopulatedProject(data.project);
        // showSnackBarMessage('Loaded project details.');
      } catch (error) {
        showSnackBarMessage(
          error.response?.data.message || 'Unable to fetch project details.'
        );
      }
      // setIsLoading(false);
    };

    // Get payment methods
    const fetchPaymentValidity = async () => {
      // setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/payments/valid_pm?userId=${auth.userId}`
        );
        setIsPaymentMethodValid(data.valid);
      } catch (error) {
        setIsPaymentMethodValid(false);
        // showSnackBarMessage(
        //   error.response?.data.message ||
        //     'Error retrieving payment information.'
        // );
      }
      setIsLoading(false);
    };

    fetchPopulatedProject();
    fetchPaymentValidity();

    // return () => {
    //   if (dataContext.getProject()?.name) {
    //     dataContext.storeProject(); // clean up
    //   }
    // };
  }, [dataContext, auth]);

  const handleJobSubmission = async (
    jobType,
    outlineIdArray,
    clearSelections
  ) => {
    if (!project.id) {
      showSnackBarMessage(
        'Something went wrong, please try navigating to the dashboard and back to fix the issue.'
      );
      return;
    }

    if (!outlineIdArray) {
      showSnackBarMessage(
        'Outlines expected, but received none. Skipping outline submission.'
      );
      return;
    }

    // TODO: inefficient to submit one id at a time, change API to accommodate array input in JSON
    setIsLoading(true);
    for (const outlineId of outlineIdArray) {
      // console.log(outlineId);
      try {
        // RESPONSE FORMAT: response.data = {job: {}, project: {}}
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/jobs`,
          {
            jobType: jobType,
            userId: project.creator,
            projectId: project.id,
            files: project.files,
            outline: outlineId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        setProject(data.project);
        showSnackBarMessage(
          `Outline queued for content generation. Job ID: ${data.job?.id}`
        );
      } catch (error) {
        showSnackBarMessage(
          error.response?.data.message ||
            'Unable to initiate the content generation process.'
        );
      }
    }
    clearSelections();
    dataContext.refreshProject(project.id);
    setIsLoading(false);
  };

  const showSnackBarMessage = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      {/* <Typography variant='title'>Content Generation</Typography> */}
      <ProjectStepper project={project} activeStep={2} />

      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={12} sm={6}>
            {/* Panel 1: Project details */}

            <Paper elevation={4} sx={style.paper}>
              <Typography
                textAlign='center'
                variant='body1'
                fontWeight={700}
                color='primary'
              >
                Project
              </Typography>
              <FMDetails project={project} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* Panel 2: Inputs */}
            <Paper elevation={4} sx={style.paper}>
              <Typography
                textAlign='center'
                variant='body1'
                fontWeight={700}
                color='primary'
              >
                Inputs
              </Typography>
              <GenInputs project={project} />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {/* Panel 3: General instructions */}
            <Paper elevation={4} sx={{ p: 2 }}>
              <Typography
                textAlign='center'
                variant='body1'
                fontWeight={700}
                color='primary'
              >
                Submit outline
              </Typography>
              {/* <GenActions
                project={project}
                handleProcessLock={handleProcessLock}
              /> */}
              <OutlineSelection
                populatedProject={populatedProject}
                handleJobSubmission={handleJobSubmission}
                paymentValidity={isPaymentMethodValid}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setSnackbarOpen(false)}
          >
            <CancelRoundedIcon fontSize='small' />
          </IconButton>
        }
      />
    </>
  );
};

export default GeneratePage;
