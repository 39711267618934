import React, { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../shared/context/data-context';
import { AuthContext } from '../../shared/context/auth-context';
import {
  Alert,
  Box,
  Container,
  InputAdornment,
  TextField,
} from '@mui/material';
import LoadingButton from '../../shared/components/ui/LoadingButton';

import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';
import IconTemplate from '../public/components/IconTemplate';

const validationSchema = Yup.object().shape({
  to: Yup.string().email('Invalid format').required('Required'),
  name: Yup.string().required('Required'),
  message: Yup.string(),
});

const Invite = () => {
  const dataContext = useContext(DataContext);
  const auth = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState();
  const [invitorFn, setInvitorFn] = useState('');
  const [invitorLn, setInvitorLn] = useState('');
  const [invitorEmail, setInvitorEmail] = useState('');

  const [responseMessage, setResponseMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    reset,
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    const project = dataContext.getProject();
    if (project) {
      setProject(project);
    }
  }, [dataContext]);

  useEffect(() => {
    if (auth.user) {
      setInvitorFn(auth.user.firstname);
      setInvitorLn(auth.user.lastname);
      setInvitorEmail(auth.user.email);
    }
  }, [auth]);

  useEffect(() => {
    if (Object.keys(errors)?.length > 0) {
      setResponseMessage(null);
    }
  }, [errors]);

  // Set and update dynamic value
  useEffect(() => {
    if (project?.name) {
      setValue(
        'message',
        `${invitorFn} would like to collaborate on ${project.description}.`
      );
    }
  }, [setValue, project, invitorFn]);

  const handleFormSubmission = async (formData) => {
    // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    if (formData.to === invitorEmail) {
      setError('to', { type: 'manual', message: 'Inviting yourself?' });
      return;
    }

    setIsLoading(true);
    setResponseMessage(null);
    setErrorMessage(null);

    try {
      // await delay(2000);
      formData['invitorName'] = `${invitorFn} ${invitorLn}`;
      formData['invitorEmail'] = invitorEmail;
      await axios.post(
        `${process.env.REACT_APP_API_URL}/email/invite`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      setResponseMessage(
        `Invitation to join CorpusKey sent to ${formData.name}.`
      );
      reset();
    } catch (error) {
      setErrorMessage(
        error.response?.data.message ||
          `Unable to invite ${formData.name}. Please try again in a while.`
      );
    }

    setIsLoading(false);
  };

  return (
    <Container component='main' maxWidth='xs' sx={{ mt: 2, mb: 2 }}>
      <IconTemplate title='Invite a collaborator' image={SendIcon} />

      <Box
        component='form'
        onSubmit={handleSubmit(handleFormSubmission)}
        noValidate
        sx={{ mt: 1 }}
      >
        <TextField
          margin='normal'
          fullWidth
          value={`${invitorFn} ${invitorLn}`}
          label='From'
          disabled
        />

        <TextField
          {...register('name')}
          margin='normal'
          required
          fullWidth
          name='name'
          label='Name'
          id='name'
          {...(errors.name && {
            error: true,
            helperText: errors.name.message,
          })}
          variant='filled'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          {...register('to')}
          margin='normal'
          required
          fullWidth
          id='to'
          label='Email'
          name='to'
          autoFocus
          {...(errors.to && {
            error: true,
            helperText: errors.to.message,
          })}
          variant='filled'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          {...register('message')}
          margin='normal'
          multiline
          rows={2}
          id='message'
          label='Optional message'
          name='message'
          variant='filled'
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <MessageIcon />
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 2, mb: 2 }}
          isLoading={isLoading}
        >
          Invite
        </LoadingButton>

        {responseMessage && (
          <Alert severity='success' sx={{ mt: 1 }}>
            {responseMessage}
          </Alert>
        )}
        {errorMessage && <Alert severity='error'>{errorMessage}</Alert>}
      </Box>
    </Container>
  );
};

export default Invite;
