import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

const TextParser = ({ setJsonArray, setEnableSave, delimiter = '\t' }) => {
  const theme = useTheme();
  const [inputText, setInputText] = useState('section	title	prompt	url\n');
  const [showDiscard, setShowDiscard] = useState(false);

  const handleTextChange = (event) => {
    setInputText(event.target.value);
  };

  const handleParse = () => {
    const lines = inputText.split('\n');
    const header = lines[0].split(delimiter).map((value) => value.trim());

    const jsonArray = [];
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(delimiter);
      const obj = {};
      obj['seq'] = i;

      // skip empty lines
      if (currentLine && currentLine.length < 2) {
        continue;
      }

      currentLine.forEach((value, index) => {
        let trimmedValue = value.trim();

        if (trimmedValue.startsWith('"') && trimmedValue.endsWith('"')) {
          trimmedValue = trimmedValue.slice(1, -1);
        }

        obj[header[index]] = trimmedValue;
      });

      jsonArray.push(obj);
    }

    setJsonArray(jsonArray);
    setEnableSave(true);
    setShowDiscard(true);
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Grid
        container
        spacing={1}
        justifyContent='center'
        alignItems='center'
        component={Paper}
        sx={{ p: 2 }}
        elevation={4}
      >
        <Grid item xs={12}>
          <Typography variant='h6' color={theme.palette.warning.main}>
            Create / overwrite outline using tab separated text
          </Typography>
          <Typography
            variant='body2'
            color={theme.palette.error.main}
            fontSize={11}
          >
            Caution: Advanced Feature - Do not use if unsure how it functions. Will cause errors.  Must turn off auto-save at first use.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label='Enter Tab Separated Text'
            id='outlineText'
            multiline
            rows={5}
            variant='outlined'
            fullWidth
            value={inputText}
            onChange={handleTextChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant='body2'
            color={theme.palette.warning.main}
            visibility={showDiscard ? 'visible' : 'hidden'}
          >
            Unexpected result? Hit 'Discard' and navigate back to this page.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          {showDiscard && (
            <Button component={Link} to='/landing' variant='outlined' fullWidth>
              Discard
            </Button>
          )}
        </Grid>
        <Grid item xs={2}>
          <Button onClick={handleParse} variant='contained' fullWidth>
            Convert
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default TextParser;
