import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import LoadingButton from '../../../../shared/components/ui/LoadingButton';

const FMDeleteModal = ({ isOpen, setIsOpen, file, handleFileDelete }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = (event) => {
    event.preventDefault();
    setIsLoading(true);
    handleFileDelete(file.id, setIsLoading);
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => setIsOpen(!isOpen)}
      aria-labelledby='delete-modal'
      aria-describedby='delete-file'
    >
      <Box
        minWidth={theme.breakpoints.values.sm}
        justifyContent='center'
        alignItems='center'
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: `1px solid ${theme.palette.primary.dark}`,
          borderRadius: '8px',
          boxShadow: 12,
          p: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card elevation={12} sx={{ width: '100%' }}>
          <CardHeader
            title='Delete File'
            action={
              <Tooltip title='Close'>
                <IconButton
                  aria-label='close'
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <CancelRoundedIcon />
                </IconButton>
              </Tooltip>
            }
            sx={{ backgroundColor: theme.palette.primary.dark, color: 'white' }}
          />
          <Box
            component='form'
            onSubmit={handleDelete}
            noValidate
            sx={{ mt: 1 }}
          >
            <CardContent>
              <Grid
                container
                direction='column'
                alignItems='center'
                justifyContent='center'
                spacing={2}
              >
                <Grid item>
                  <TextField
                    margin='normal'
                    fullWidth
                    id='name'
                    label='File Name'
                    name='name'
                    variant='filled'
                    disabled
                    value={file.name}
                  />

                  <TextField
                    margin='normal'
                    fullWidth
                    id='description'
                    label='File Tyoe'
                    name='description'
                    variant='filled'
                    disabled
                    value={file.mimeType}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <LoadingButton
                type='submit'
                isLoading={isLoading}
                variant='contained'
                color='error'
              >
                Delete File
              </LoadingButton>
            </CardActions>
          </Box>
        </Card>
      </Box>
    </Modal>
  );
};

export default FMDeleteModal;
