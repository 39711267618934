import { createTheme } from '@mui/material/styles';
// import { green, purple } from "@mui/material/colors";

const arkBlue = '#0B72B9';
const arkOrange = '#FFBA60';

const theme2 = createTheme({
  palette: {
    common: {
      blue: `${arkBlue}`,
      orange: `${arkOrange}`,
    },
    primary: {
      main: `${arkBlue}`,
      // main: purple[400],
    },
    secondary: {
      main: `${arkOrange}`,
      // main: green[400],
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 12,
    fontFamily: '"Manrope", sans-serif',
    button: {
      textTransform: 'none',
      fontWeight: 'medium',
    },
    tab: {
      textTransform: 'none',
      fontWeight: 700,
      fontSize: '1.1rem',
      '&.Mui-selected': {
        color: 'white',
      },
    },
    title: {
      fontSize: 20,
      color: 'white',
      marginLeft: 24,
      // background: `linear-gradient(to right, ${arkBlue}, ${arkOrange})`,
      background: `${arkBlue}`,
      padding: 8,
      borderBlockStyle: 'solid',
      borderBlockWidth: '3px',
      borderBlockColor: `${arkOrange}`,
    },
    titleText: {
      color: `${arkBlue}`,
      textDecoration: 'underline',
      textDecorationColor: `${arkOrange}`,
      textDecorationThickness: '3px',
      textUnderlineOffset: '5px',
    },
    smallerFontBold: {
      fontFamily: 'Manrope, sans-serif',
      fontSize: '0.88rem',
      fontWeight: 'bold',
    },
    smallerFont: {
      fontFamily: 'Manrope, sans-serif',
      fontSize: '0.88rem',
    },
  },
});

export default theme2;
