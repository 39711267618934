import React from 'react';
import { Button, CircularProgress } from '@mui/material';

const LoadingButton = ({ isLoading, children, disabled, ...other }) => {
  return (
    <Button {...other} disabled={isLoading || disabled}>
      {isLoading ? (
        <CircularProgress color='secondary' size='1.5rem' />
      ) : (
        children
      )}
    </Button>
  );
};

export default LoadingButton;
