import React, { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { DataContext } from '../../shared/context/data-context';
import { style } from '../../shared/styles/style';
import MultiFileUpload from './components/file/MultiFileUpload';
import FMDetails from './components/file/FMDetails';
import FMList from './components/file/FMList';
import FMDeleteModal from './components/file/FMDeleteModal';
import ProjectStepper from '../../shared/components/ui/ProjectStepper';
import ThumbnailModal from '../../shared/components/ui/ThumbnailModal';
import TabPanel from '../../shared/components/ui/TabPanel';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ImageIcon from '@mui/icons-material/Image';

const FileManagementPage = () => {
  const theme = useTheme();
  const [project, setProject] = useState();
  const dataContext = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedFiles, setLoadedFiles] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isThumbnailModalOpen, setIsThumbnailModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const project = dataContext.getProject();
    setProject(project);

    const fetchFiles = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/files/${project.id}`
        );
        setLoadedFiles(data.files);
      } catch (error) {
        setLoadedFiles([]);
        setSnackbarMessage(
          error.response?.data.message || 'Unable to retrieve files.'
        );
        setSnackbarOpen(true);
      }
      setIsLoading(false);
    };

    if (project.id) {
      fetchFiles();
    }

    // return () => {
    //   if (dataContext.getProject()?.name) {
    //     dataContext.storeProject(); // clean up
    //   }
    // };
  }, [dataContext]);

  const handleFileUpload = (file) => {
    setLoadedFiles([...loadedFiles, file]);
    // Refresh project context
    dataContext.refreshProject(project.id);
  };

  const openDeleteModal = (file) => {
    setFile(file);
    setIsDeleteModalOpen(true);
  };

  const openImageModal = (file) => {
    setImage(file);
    setIsThumbnailModalOpen(true);
  };

  const onCloseThumbnailModal = () => {
    setIsThumbnailModalOpen(false);
  };

  const handleFileDelete = (fileId, resetLoadingButton) => {
    const deleteFile = async () => {
      try {
        // eslint-disable-next-line
        const { data } = await axios.delete(
          `${process.env.REACT_APP_API_URL}/files/${fileId}`
        );

        const updatedFiles = loadedFiles.filter((file) => {
          return file.id !== fileId; // FKT - Filter Keeps True
        });
        setLoadedFiles(updatedFiles);
        setIsDeleteModalOpen(false);
        resetLoadingButton(false);
      } catch (error) {
        setSnackbarMessage(
          error.response?.data.message || 'Unable to delete file.'
        );
        setSnackbarOpen(true);
        setIsDeleteModalOpen(false);
        resetLoadingButton(false);
      }

      // refresh the project context
      dataContext.refreshProject(project.id);
    };

    deleteFile();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      {/* <Typography variant='title'>Manage Project Files</Typography> */}
      <ProjectStepper project={project} activeStep={0} />

      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={12} sm={6}>
            {/* Panel 1: Project details */}
            <Paper elevation={4} sx={style.paper}>
              <Typography
                textAlign='center'
                variant='body1'
                fontWeight={700}
                color='primary'
              >
                Project Details
              </Typography>
              <FMDetails project={project} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* Panel 2: File upload component */}
            {/* <FileUpload handleFileUpload={handleFileUpload} /> */}
            <MultiFileUpload
              project={project}
              updateFileList={handleFileUpload}
            />
          </Grid>
          <Grid item xs={12}>
            {/* Panel 3: File list */}
            <Paper elevation={4} sx={{ p: 2, minHeight: '30vw' }}>
              <Typography
                textAlign='center'
                variant='body1'
                fontWeight={700}
                color='primary'
              >
                Files
              </Typography>

              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label='files tabs'
                >
                  <Tab
                    label='Documents'
                    icon={
                      isLoading ? (
                        <CircularProgress size={20} />
                      ) : (
                        <TextSnippetIcon fontSize='small' />
                      )
                    }
                    iconPosition='start'
                  />
                  <Tab
                    label='Images'
                    icon={<ImageIcon fontSize='small' />}
                    iconPosition='start'
                  />
                </Tabs>
              </Box>

              <TabPanel value={tabValue} index={0}>
                <FMList
                  files={loadedFiles?.filter((file) => !file.isImage)}
                  type='text documents'
                  openDeleteModal={openDeleteModal}
                  openImageModal={openImageModal}
                  error={loadedFiles?.length > 0 ? null : snackbarMessage}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <FMList
                  files={loadedFiles?.filter((file) => file.isImage)}
                  type='images'
                  openDeleteModal={openDeleteModal}
                  openImageModal={openImageModal}
                  error={loadedFiles?.length > 0 ? null : snackbarMessage}
                />
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {file && (
        <FMDeleteModal
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
          file={file}
          handleFileDelete={handleFileDelete}
        />
      )}

      {image && (
        <ThumbnailModal
          isOpen={isThumbnailModalOpen}
          onClose={onCloseThumbnailModal}
          file={image}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          variant='filled'
          onClose={() => setSnackbarOpen(false)}
          severity='error'
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default FileManagementPage;
