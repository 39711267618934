import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import ContentHeader from './ContentHeader';
import ContentBody from './ContentBody';
import ContentBiblio from './ContentBiblio';

const GenContentDetails = ({ content }) => {
  if (!content) {
    return (
      <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography textAlign="center" variant="body2">
              Select a outline item to view generated content
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <>
      <ContentHeader
        seq={content.seq}
        section={content.section}
        title={content.title}
        prompt={content.prompt}
      />
      <ContentBody
        subtitle={content.subtitle}
        final={content.final}
        definition={content.definition}
        bullets={content.bullets}
      />

      <ContentBiblio biblio={content.bibliography} />
    </>
  );
};

export default GenContentDetails;
