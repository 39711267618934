import React from 'react';
import { Container, Grid, Typography, useTheme } from '@mui/material';

const Template = ({ title, children, ...props }) => {
  const theme = useTheme();

  return (
    <Container component='main' sx={{ mb: 4 }} {...props}>
      <Typography variant='h4' align='center' sx={theme.typography.titleText}>
        {title}
      </Typography>

      <Grid
        container
        spacing={2}
        justifyContent='center'
        sx={{ marginTop: '5px' }}
      >
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Template;
