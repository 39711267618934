import React from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
// import PageviewIcon from '@mui/icons-material/Pageview';
// import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const GenInputs = ({ project }) => {
  // const theme = useTheme();

  return (
    <>
      <TableContainer sx={{ pt: 2 }}>
        <Table size='small'>
          <TableBody>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Typography variant='smallerFont'>Files</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='smallerFont'>
                  {project?.files?.length}
                </Typography>
              </TableCell>
              {/* <TableCell>
                <Tooltip title='Review files'>
                  <IconButton component={Link} to={`/files/${project?.id}`}>
                    <PageviewIcon
                      fontSize='large'
                      sx={{ color: theme.palette.primary.main }}
                    />
                  </IconButton>
                </Tooltip>
              </TableCell> */}
            </TableRow>
            <TableRow
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Typography variant='smallerFont'>Outline</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='smallerFont'>
                  {project?.outlines?.length > 0 ? 'Defined' : 'To be defined'}
                </Typography>
              </TableCell>
              {/* <TableCell>
                <Tooltip title='Review outline'>
                  <IconButton component={Link} to={`/outline/${project?.id}`}>
                    <ManageSearchIcon
                      fontSize='large'
                      sx={{ color: theme.palette.primary.main }}
                    />
                  </IconButton>
                </Tooltip>
              </TableCell> */}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container justifyContent='flex-end' alignItems='flex-end'>
        <Grid item></Grid>
        <Grid item></Grid>
      </Grid>
    </>
  );
};

export default GenInputs;
