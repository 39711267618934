import React from 'react';
import {
  ListItem,
  ListItemButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { JOB_TYPES } from '../../../utils/job-params';

const ContentItem = ({ job, handleItemSelection, count }) => {
  const theme = useTheme();

  let jobName = 'Unknown';
  try {
    const typesArr = JOB_TYPES.filter((item) => item.value === job.jobType);
    jobName = typesArr[0].name;
  } catch (error) {}

  let statusColor = theme.palette.success.main;
  if (job.status === 'Failed') {
    statusColor = theme.palette.error.main;
  } else if (job.status === 'Processing...') {
    statusColor = theme.palette.warning.main;
  } else if (job.status === 'INIT') {
    statusColor = theme.palette.warning.dark;
  }

  const transformStatusMessage = (status, createdTS) => {
    if (!status || !createdTS) {
      return;
    }

    try {
      const elapsedMins = (Date.now() - new Date(createdTS)) / (1000 * 60);
      if (status === 'INIT' && elapsedMins > 60) {
        return 'Queued for too long';
      } else if (status === 'Processing...' && elapsedMins > 60) {
        return 'Unable to process';
      } else if (status === 'INIT') {
        return 'Queued';
      } else {
        return status;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ListItem>
        <ListItemButton onClick={() => handleItemSelection(job.id)}>
          <Stack direction={'column'} spacing={0}>
            <Typography color='text.primary' variant='body2' fontWeight={700}>
              {count}. {jobName}
            </Typography>
            <Typography variant='body2'>
              {job.created
                ? new Date(job.created).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })
                : 'Unknown'}
            </Typography>

            <Typography variant='body2' display='inline' color={statusColor}>
              {transformStatusMessage(job.status, job.created)}
            </Typography>
          </Stack>
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default ContentItem;
