import React from 'react';
import { Grid, Typography } from '@mui/material';
import IconTemplate from './components/IconTemplate';
import GavelIcon from '@mui/icons-material/Gavel';

const TermsOfUse = () => {
  return (
    <IconTemplate title='Terms of Use' image={GavelIcon} sx={{ mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            These Terms of Use ("Terms") govern your access to and use of the
            software as a service ("Service") provided by CorpusKey ("we," "us,"
            or "our"). By accessing or using our Service, you agree to be bound
            by these Terms. If you do not agree to these Terms, please do not
            access or use the Service.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            1. Acceptance of Terms:{' '}
          </Typography>
          <Typography variant='smallerFont'>
            By accessing or using the Service, you agree to be bound by these
            Terms, including any additional terms and conditions and policies
            referenced herein.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            2. Use of the Service:{' '}
          </Typography>
          <Typography variant='smallerFont'>
            You may use the Service solely for your own personal or internal
            business purposes in accordance with these Terms. You may not use
            the Service for any illegal or unauthorized purpose.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            3. Account Registration:{' '}
          </Typography>
          <Typography variant='smallerFont'>
            In order to access certain features of the Service, you may be
            required to register for an account. You agree to provide accurate
            and complete information when registering for an account and to keep
            your account information updated.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            4. Fees and Payment:{' '}
          </Typography>
          <Typography variant='smallerFont'>
            Certain features of the Service may be subject to payment of fees.
            You agree to pay all applicable fees in accordance with the pricing
            and payment terms provided to you for such features.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            5. Intellectual Property:{' '}
          </Typography>
          <Typography variant='smallerFont'>
            The Service and its original content, features, and functionality
            are and will remain the exclusive property of CorpusKey and its
            licensors. The Service is protected by copyright, trademark, and
            other laws of both the U.S.A and foreign countries. Our trademarks
            and trade dress may not be used in connection with any product or
            service without the prior written consent of CorpusKey.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>6. Privacy: </Typography>
          <Typography variant='smallerFont'>
            Your use of the Service is subject to our Privacy Policy, which is
            hereby incorporated by reference into these Terms. Please review our
            Privacy Policy for information about how we collect, use, and
            disclose information about you.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>7. User Content: </Typography>
          <Typography variant='smallerFont'>
            You may be able to submit, post, or display content on or through
            the Service ("User Content"). You retain ownership of any
            intellectual property rights that you hold in the User Content. By
            submitting, posting, or displaying User Content on or through the
            Service, you grant us a non-exclusive, royalty-free, worldwide,
            sublicensable, and transferable license to use, reproduce, adapt,
            modify, distribute, and display such User Content in connection with
            the Service.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            8. Prohibited Conduct:{' '}
          </Typography>
          <Typography variant='smallerFont'>
            You may not engage in any of the following prohibited activities in
            connection with the Service: (a) use the Service for any illegal
            purpose or in violation of any applicable laws; (b) engage in any
            activity that interferes with or disrupts the Service or the servers
            and networks used to provide the Service; (c) attempt to gain
            unauthorized access to the Service or any other user's account; (d)
            use any robot, spider, or other automated device, process, or means
            to access the Service for any purpose; (e) use the Service to
            transmit any viruses, worms, defects, or other items of a
            destructive nature; or (f) use the Service to violate the security
            or integrity of any network, computer, or communications system.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>9. Termination: </Typography>
          <Typography variant='smallerFont'>
            We may terminate or suspend your access to the Service immediately,
            without prior notice or liability, for any reason whatsoever,
            including without limitation if you breach these Terms.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            10. Changes to Terms:{' '}
          </Typography>
          <Typography variant='smallerFont'>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material, we will provide
            at least 30 days' notice prior to any new terms taking effect. What
            constitutes a material change will be determined at our sole
            discretion.
          </Typography>
        </Grid>

        <Grid item xs={12} variant='smallerFontBold'>
          <Typography variant='smallerFont'>
            If you have any questions about these Terms, please contact us.
          </Typography>{' '}
          <Typography variant='smallerFontBold'>
            By accessing or using the Service, you acknowledge that you have
            read, understood, and agree to be bound by these Terms.
          </Typography>
        </Grid>
      </Grid>
    </IconTemplate>
  );
};

export default TermsOfUse;
