import React from "react";
import Description from "./Description";
import How from "./How";
import Categories from "./Categories";
import BottomHero from "./BottomHero";
import Banner from "./Banner";

const Home = () => {
  return (
    <main>
      <Banner />
      <Description />
      <Categories />
      <How />
      <BottomHero />
    </main>
  );
};

export default Home;
