import { useState, useEffect, useCallback } from 'react';
import { toMilliSeconds } from '../../utils/time-converter';

let logoutTimer;

export const useAuth = () => {
  const [userId, setUserId] = useState();
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null); // object with fn, ln, email, sCustId and status
  const [tokenExpirationDate, setTokenExpirationDate] = useState();

  const login = useCallback((userId, token, user, expirationDate) => {
    setToken(token);
    setUserId(userId);
    setUser(user);
    const tokenExpirationDate =
      expirationDate ||
      new Date(
        new Date().getTime() + toMilliSeconds(process.env.REACT_APP_LOGIN_VALID)
      );
    // expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60); //+1h
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId,
        token,
        user,
        expiration: tokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setUser(null);
    localStorage.removeItem('userData');
  }, []);

  // if token has not expired, log the user in
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date() // stored token expiry > now
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.user,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  // Auto logout when token expires
  useEffect(() => {
    if (token && tokenExpirationDate) {
      const reaminingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, reaminingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  return { userId, token, user, login, logout };
};
