import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Tab, Tabs } from '@mui/material';
import AccountMenu from './AccountMenu';
import DashboardIcon from '@mui/icons-material/Dashboard';

const styles = {
  hide: {
    style: { display: 'none' },
  },
  show: {
    style: { opacity: 1 },
  },
  selectedBlend: {
    '&.Mui-selected': {
      opacity: 0.6,
    },
  },
  selectedShow: {
    '&.Mui-selected': {
      opacity: 1,
    },
  },
};

const HeaderTabs = ({
  auth,
  navLinks,
  tabIndex,
  setTabIndex,
  hideIndicator,
  setHideIndicator,
}) => {
  return (
    <>
      <Tabs
        textColor='inherit'
        value={tabIndex}
        onChange={(event, val) => {
          setHideIndicator(false);
          setTabIndex(val);
        }}
        indicatorColor='secondary'
        TabIndicatorProps={hideIndicator ? styles.hide : styles.show}
      >
        {navLinks.map((item, index) => (
          <Tab
            key={index}
            label={item.label}
            component={Link}
            to={item.url}
            disableRipple
            sx={hideIndicator ? styles.selectedBlend : styles.selectedShow}
          />
        ))}
      </Tabs>
      {!auth.isLoggedIn && (
        <>
          <Button
            sx={{ marginLeft: 'auto' }}
            variant='contained'
            color='primary'
            component={Link}
            onClick={() => setHideIndicator(true)}
            to='/login'
          >
            Login
          </Button>
          <Button
            sx={{ marginLeft: '1rem' }}
            variant='contained'
            color='secondary'
            component={Link}
            onClick={() => setHideIndicator(true)}
            to='/signup'
          >
            Sign up
          </Button>
        </>
      )}

      {auth.isLoggedIn && (
        <>
          {/* Buttons replaced by AccountMenu */}
          <Button
            sx={{ marginLeft: 'auto' }}
            variant='outlined'
            color='secondary'
            component={Link}
            onClick={() => setHideIndicator(true)}
            to='/landing'
            startIcon={<DashboardIcon />}
          >
            Dashboard
          </Button>
          {/* <Button
            sx={{ marginLeft: '1rem' }}
            variant='outlined'
            color='secondary'
            onClick={() => {
              setHideIndicator(true);
              auth.logout();
            }}
          >
            Logout
          </Button> */}
          <AccountMenu setHideIndicator={setHideIndicator} auth={auth} />
        </>
      )}
    </>
  );
};

export default HeaderTabs;
