import React from 'react';
import { Container, Grid, List, Typography } from '@mui/material';
import ContentItem from './ContentItem';

const ContentList = ({ jobs, handleItemSelection }) => {
  if (!jobs || jobs.length === 0) {
    return (
      <Container maxWidth='lg' sx={{ mt: 2, mb: 2 }}>
        <Grid container justifyContent='center'>
          <Grid item xs={12}>
            <Typography textAlign='center' variant='body2'>
              No content items to display
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {jobs.map((job, index) => {
        return (
          <ContentItem
            key={index}
            count={jobs.length - index}
            job={job}
            handleItemSelection={handleItemSelection}
          />
        );
      })}
    </List>
  );
};

export default ContentList;
