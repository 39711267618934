import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Snackbar,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { DataContext } from '../../shared/context/data-context';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import TextParser from './components/TextParser';
import ProjectStepper from '../../shared/components/ui/ProjectStepper';
import MRTRenderer from './components/MRTRenderer';

const OutlinePageMRT = () => {
  const dataContext = useContext(DataContext);
  const { outlineId } = useParams('outlineId'); // outlineId from URL

  const theme = useTheme();
  const [outlineName, setOutlineName] = useState();
  const [jsonArray, setJsonArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [showTextParser, setShowTextParser] = useState(false);
  const [autoSave, setAutoSave] = useState(true);
  const [enableSave, setEnableSave] = useState(false);

  useEffect(() => {
    const project = dataContext.getProject();
    setProject(project);
    // console.log(project);

    const fetchOutline = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/outlines/outline/${outlineId}`
        );
        setJsonArray(data.outline.outline);
        setOutlineName(data.outline.name);
      } catch (error) {
        setJsonArray([]);
        setSnackbarMessage(
          error.response?.data.message || 'Unable to retrieve outline.'
        );
        setSnackbarOpen(true);
      }
      setIsLoading(false);
    };

    if (outlineId) {
      fetchOutline();
    }
  }, [outlineId, dataContext]);

  const saveOutline = async (updatedOutlineArray) => {
    // Check is there's anything to save
    if (
      !updatedOutlineArray ||
      (updatedOutlineArray && updatedOutlineArray?.length === 0)
    ) {
      setSnackbarMessage(
        'Nothing to save. Use the form above to start creating an outline.'
      );
      setSnackbarOpen(true);
      return;
    }

    let data;
    setIsLoading(true);
    try {
      if (outlineId) {
        // outline exists, save with id
        const response = await axios.patch(
          `${process.env.REACT_APP_API_URL}/outlines/${outlineId}`,
          { outline: updatedOutlineArray },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        data = response.data;
        setJsonArray(data.outlines.outline);
        setEnableSave(false);
        setShowTextParser(false);
        showSnackBarMessage('Outline saved!');
      }
    } catch (error) {
      setSnackbarMessage(
        error.response?.data.message || 'Unable to create/ update outline.'
      );
      setSnackbarOpen(true);
    }
    setIsLoading(false);
  };

  const showSnackBarMessage = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const toggleTextParserVis = () => {
    setShowTextParser(!showTextParser);
  };

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      <ProjectStepper
        project={project}
        activeStep={1}
        crumb={`Outline: ${outlineName}`}
      />

      {showTextParser && (
        <TextParser setJsonArray={setJsonArray} setEnableSave={setEnableSave} />
      )}

      {/* <Container sx={{ mt: 4 }}> */}
      <MRTRenderer
        data={jsonArray}
        setData={setJsonArray}
        toggleTextParserVis={toggleTextParserVis}
        saveOutline={saveOutline}
        autoSave={autoSave}
        setAutoSave={setAutoSave}
        enableSave={enableSave}
      />
      {/* </Container> */}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setSnackbarOpen(false)}
          >
            <CancelRoundedIcon fontSize='small' />
          </IconButton>
        }
      />
    </>
  );
};

export default OutlinePageMRT;
