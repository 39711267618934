import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import { Box, Icon, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { AuthContext } from '../../context/auth-context';

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import NavDrawer from './NavDrawer';
import HeaderTabs from './HeaderTabs';
import HomeIcon from '@mui/icons-material/Home';
import QrCodeIcon from '@mui/icons-material/QrCode';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HelpIcon from '@mui/icons-material/Help';
import styled from '@emotion/styled';

const navLinks = [
  { id: 'home', idx: 0, label: 'Home', url: '/', icon: HomeIcon },
  {
    id: 'products',
    idx: 1,
    label: 'Products',
    url: '/products',
    icon: QrCodeIcon,
  },
  { id: 'about', idx: 2, label: 'About', url: '/about', icon: InfoIcon },
  {
    id: 'contact',
    idx: 3,
    label: 'Contact',
    url: '/contact',
    icon: ContactMailIcon,
  },
  {
    id: 'help',
    idx: 4,
    label: 'Help',
    url: '/help',
    icon: HelpIcon,
  },
];

const StyledIcon = styled(Icon)({
  cursor: 'default',
  '&:hover': {
    cursor: 'pointer',
  },
});

const MainNav = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [tabIndex, setTabIndex] = useState(0);
  const [hideIndicator, setHideIndicator] = useState(false);

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const inList = navLinks.filter((item) => item.url === location.pathname);
    if (inList.length === 0) {
      setHideIndicator(true);
    } else {
      setTabIndex(inList[0].idx);
      setHideIndicator(false);
    }
  }, [location]);

  return (
    <>
      <AppBar
        sx={{
          bgcolor: 'primary.dark',
        }}
      >
        <Toolbar>
          <StyledIcon>
            <Icon
              component={VpnKeyIcon}
              onClick={() => {
                navigate('/');
                setTabIndex(0);
              }}
            />
          </StyledIcon>

          {isMatch ? (
            <>
              {/* <Typography
                sx={{ fontSize: '1.25rem', paddingLeft: '1rem' }}
                onClick={() => {
                  navigate('/');
                  setTabIndex(0);
                }}
              >
                CorpusKey
              </Typography> */}
              <NavDrawer
                auth={auth}
                navLinks={navLinks}
                setTabIndex={setTabIndex}
                setHideIndicator={setHideIndicator}
              />
            </>
          ) : (
            <HeaderTabs
              auth={auth}
              navLinks={navLinks}
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
              hideIndicator={hideIndicator}
              setHideIndicator={setHideIndicator}
            />
          )}
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          ...theme.mixins.toolbar,
          marginBottom: '1em',
          [theme.breakpoints.down('md')]: { marginBottom: '0.75em' },
          [theme.breakpoints.down('xs')]: { marginBottom: '0.6em' },
        }}
      />
    </>
  );
};

export default MainNav;
