import React from 'react';
import { Button, TableCell, TableRow, Typography } from '@mui/material';
import { splitEmail } from '../../../utils/helpers';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const UserItem = ({ index, user, project, handleAddRemoveActions }) => {
  return (
    <TableRow>
      <TableCell>
        <Typography variant='smallerFont'>{index}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant='smallerFont'>
          {user.firstname} {user.lastname}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='smallerFont'>
          {splitEmail(user.email)?.[1] || 'Unknown'}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='smallerFont'>
          {user.affiliation || 'Unknown'}
        </Typography>
      </TableCell>

      {user.collabProjects?.includes(project.id) ? (
        <TableCell>
          <Button
            variant='outlined'
            size='small'
            color='error'
            fullWidth
            onClick={() => handleAddRemoveActions(user.id, 'remove')}
          >
            Remove from project
          </Button>
        </TableCell>
      ) : project?.creator === user.id ? (
        <TableCell>
          <Button variant='outlined' size='small' fullWidth disabled>
            Project Owner
          </Button>
        </TableCell>
      ) : (
        <TableCell>
          <Button
            variant='outlined'
            size='small'
            color='primary'
            fullWidth
            onClick={() => handleAddRemoveActions(user.id, 'add')}
          >
            Add to project
          </Button>

          {/* <Tooltip title='Remove from project'>
            <IconButton>
              <RemoveCircleIcon sx={{ color: theme.palette.error.light }} />
            </IconButton>
          </Tooltip> */}
        </TableCell>
      )}
    </TableRow>
  );
};

export default UserItem;
