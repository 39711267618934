import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Grid, InputAdornment, TextField } from '@mui/material';

import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';
import IconTemplate from './components/IconTemplate';

const Contact = () => {
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  });
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&');
  };

  const validateEmail = useCallback(() => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }, [email]);

  const clearForm = () => {
    setName('');
    setEmail('');
    setMessage('');
    setErrors({
      name: false,
      email: false,
      message: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors((prevState) => {
      return {
        ...prevState,
        name: !name,
        email: !validateEmail(email),
        message: !message,
      };
    });

    if (name && email && message) {
      const submissionObj = {
        name,
        email,
        message,
      };
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', ...submissionObj }),
      })
        .then(() => {
          navigate('/contact/?success=true');
          clearForm();
          setSuccess(true);
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <IconTemplate title='Contact' image={ContactMailIcon} maxWidth='xs'>
      <form
        name='contact'
        method='POST'
        action='/contact/?success=true'
        data-netlify='true'
      >
        <Grid container spacing={2}>
          <input type='hidden' name='form-name' value='contact' />
          <Grid item xs={12}>
            <TextField
              autoFocus
              id='name'
              label='Name'
              name='name'
              variant='filled'
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              helperText={errors.name ? 'Name is required' : ''}
              error={errors.name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id='email'
              label='Email'
              name='email'
              variant='filled'
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              helperText={errors.email ? 'Valid email required' : ''}
              error={errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              rows={5}
              id='message'
              label='Message'
              name='message'
              variant='filled'
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              helperText={errors.message ? 'Required' : ''}
              error={errors.message}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='outlined'
              fullWidth
              color='secondary'
              onClick={clearForm}
            >
              Clear Form
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              type='submit'
              variant='contained'
              startIcon={<SendIcon />}
              fullWidth
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Grid>
          {success && (
            <Grid item xs={12}>
              <Alert severity='success' icon={<CheckIcon />}>
                Thank you for your message!
              </Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </IconTemplate>
  );
};

export default Contact;
