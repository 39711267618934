import React from 'react';
import { Grid } from '@mui/material';
import ProductCard from './components/ProductCard';
import BookIcon from '@mui/icons-material/Book';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import QuizIcon from '@mui/icons-material/Quiz';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import QrCodeIcon from '@mui/icons-material/QrCode';
import IconTemplate from './components/IconTemplate';

const Products = () => {
  return (
    <IconTemplate title='Products' image={QrCodeIcon}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={10}>
          <ProductCard title='Student Readings' image={BookIcon}>
            Generate modular student readings from your corpus of curated material. You guide the
            topics, sequence, and talking points from your outline. The corpus
            gives the content specific domain knowledge.
          </ProductCard>
        </Grid>

        <Grid item xs={12} sm={10} sx={{ marginLeft: 'auto' }}>
          <ProductCard title='Presentation Decks' image={LibraryBooksIcon}>
            Create presentation slides to accompany your text. These
            slides encompass key highlights from the text and include images of
            your choice integrated into the outline.
          </ProductCard>
        </Grid>

        <Grid item xs={12} sm={10}>
          <ProductCard title='Live Mini-Cases' image={DynamicFeedIcon}>
            Inject real-world news into your course material. This tool crafts
            slides with news summaries, course concepts, and class
            relevance—perfect for sparking the day’s discussions.
          </ProductCard>
        </Grid>

        <Grid item xs={12} sm={10} sx={{ marginLeft: 'auto' }}>
          <ProductCard title='Question Banks' image={QuizIcon}>
            Create tailored assessments: Craft unique multiple-choice or short
            answer questions. Use our tool to generate fresh questions for LMS
            upload or paper, even if you already have the perfect text.
          </ProductCard>
        </Grid>

        <Grid item xs={12} sm={10}>
          <ProductCard title='AI Assisted Feedback or Grading' image={FactCheckIcon}>
            Leverage AI for assessing short and long written responses
            efficiently. Save time while ensuring consistency. CorpusKey
            streamlines the feedback and grading processes for long or short form responses.
          </ProductCard>
        </Grid>

        <Grid item xs={12} sm={10} sx={{ marginLeft: 'auto', mb: 4 }}>
          <ProductCard title='Student Support' image={ChatBubbleOutlineIcon}>
            With CorpusKey, build a student portal for instant multilingual
            support. Whether it&#39;s course-related queries, syllabus info, or
            practice questions, students receive immediate assistance.
          </ProductCard>
        </Grid>
      </Grid>
    </IconTemplate>
  );
};

export default Products;
