import React from 'react';
import { Grid, Typography } from '@mui/material';
import IconTemplate from './components/IconTemplate';
import SecurityIcon from '@mui/icons-material/Security';

const Privacy = () => {
  return (
    <IconTemplate title='Privacy Policy' image={SecurityIcon} sx={{ mb: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            CorpusKey ("we," "us," or "our") is committed to protecting the
            privacy of our users. This Privacy Policy explains how we collect,
            use, disclose, and safeguard your information when you use our
            software as a service ("Service"). Please read this Privacy Policy
            carefully. By accessing or using the Service, you agree to be bound
            by this Privacy Policy. If you do not agree to this Privacy Policy,
            please do not access or use the Service.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            1. Information We Collect
          </Typography>
          <Typography variant='smallerFont'>
            <ol type='a'>
              <li>
                <Typography variant='smallerFontBold'>
                  Information You Provide:{' '}
                </Typography>
                When you use the Service, we may collect information that you
                provide directly to us, such as your name, email address,
                company name, and any other information you choose to provide.
              </li>
              <li>
                <Typography variant='smallerFontBold'>
                  Automatically Collected Information:{' '}
                </Typography>{' '}
                When you access or use the Service, we may automatically collect
                certain information about your device, including your IP
                address, operating system, browser type, and other technical
                information.
              </li>
            </ol>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            2. Use of Information
          </Typography>
          <Typography variant='smallerFont'>
            <ol type='a'>
              <li>
                <Typography variant='smallerFontBold'>
                  Provide, Operate, and Maintain the Service:{' '}
                </Typography>
                We may use the information we collect to provide, operate, and
                maintain the Service, including to authenticate users, process
                transactions, and improve the Service's functionality.
              </li>
              <li>
                <Typography variant='smallerFontBold'>
                  Communicate with You:{' '}
                </Typography>
                We may use the information we collect to communicate with you,
                including to respond to your inquiries, provide customer
                support, and send you important updates and announcements
                regarding the Service.
              </li>
              <li>
                <Typography variant='smallerFontBold'>
                  Analyze Usage:{' '}
                </Typography>
                We may use the information we collect to analyze usage of the
                Service, including to monitor and analyze trends, usage, and
                activities in connection with the Service.
              </li>
            </ol>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            3. Disclosure of Information
          </Typography>
          <Typography variant='smallerFont'>
            <ol type='a'>
              <li>
                <Typography variant='smallerFontBold'>
                  Service Providers:{' '}
                </Typography>
                We may disclose your information to third-party service
                providers who perform services on our behalf, such as payment
                processing, data analysis, and marketing services.
              </li>
              <li>
                <Typography variant='smallerFontBold'>
                  Compliance with Laws:{' '}
                </Typography>
                We may disclose your information to comply with applicable laws,
                regulations, legal processes, or government requests.
              </li>
              <li>
                <Typography variant='smallerFontBold'>
                  Business Transfers:{' '}
                </Typography>
                We may disclose your information in connection with a merger,
                acquisition, reorganization, or sale of assets, or in the event
                of bankruptcy.
              </li>
            </ol>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>4. Security</Typography>
          <Typography variant='smallerFont'>
            We take reasonable measures to protect the security of your
            information and to ensure that your information is treated securely
            and in accordance with this Privacy Policy. However, no method of
            transmission over the Internet or method of electronic storage is
            100% secure, and we cannot guarantee the absolute security of your
            information.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            5. Children's Privacy
          </Typography>
          <Typography variant='smallerFont'>
            The Service is not intended for use by children under the age of 13,
            and we do not knowingly collect personal information from children
            under the age of 13. If you are a parent or guardian and believe
            that your child has provided us with personal information, please
            contact us immediately.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            6. Changes to this Privacy Policy
          </Typography>
          <Typography variant='smallerFont'>
            We reserve the right to modify this Privacy Policy at any time. If
            we make material changes to this Privacy Policy, we will notify you
            by email or by posting a notice on the Service prior to the changes
            taking effect.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>7. Contact Us</Typography>
          <Typography variant='smallerFont'>
            f you have any questions or concerns about this Privacy Policy,
            please contact us using the form accessible from the navigation
            menu.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='smallerFontBold'>
            By accessing or using the Service, you acknowledge that you have
            read, understood, and agree to be bound by this Privacy Policy.
          </Typography>
        </Grid>
      </Grid>
    </IconTemplate>
  );
};

export default Privacy;
