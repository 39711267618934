import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from '../../../shared/components/ui/Title';
import { Button, Toolbar } from '@mui/material';

const Tile = ({ heading, caption, buttonText, children, callback, params }) => {
  return (
    <>
      <Title textAlign='center'>{heading}</Title>
      <Typography component='p' variant='h6' textAlign='center' sx={{ mt: 1 }}>
        {children}
      </Typography>
      <Typography color='text.secondary' sx={{ flex: 1 }} textAlign='center'>
        {caption}
      </Typography>

      {buttonText && (
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <Button
            LinkComponent={Link}
            sx={{ mt: 1 }}
            onClick={() => callback({ key: params.key })}
          >
            {buttonText}
          </Button>
        </Toolbar>
      )}
    </>
  );
};

export default Tile;
