import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { JOB_TYPES } from '../../../utils/job-params';
import { Link } from 'react-router-dom';

const OutlineSelection = ({
  populatedProject,
  handleJobSubmission,
  paymentValidity,
}) => {
  // console.log(populatedProject);
  const theme = useTheme();
  const [checked, setChecked] = useState([]);
  const [genType, setGenType] = useState('');

  // useEffect(() => {
  //   console.log(checked);
  // }, [checked]);

  const handleChange = (event) => {
    setGenType(event.target.value);
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const clearSelections = () => {
    setGenType('');
    setChecked([]);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    handleJobSubmission(genType, checked, clearSelections);
  };

  return (
    <>
      {populatedProject ? (
        <form onSubmit={formSubmit}>
          <Box
            sx={{ width: '100%' }}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <FormControl sx={{ minWidth: 240 }} variant='standard' size='small'>
              <InputLabel>
                <Typography variant='body1'>
                  Content generation type*
                </Typography>
              </InputLabel>
              <Select
                id='content-type-selector'
                value={genType}
                label='Type'
                onChange={handleChange}
                // renderValue={(value) => `${value}`}
              >
                <MenuItem value=''>&nbsp;</MenuItem>
                {JOB_TYPES.map((job_type) => {
                  return (
                    <MenuItem
                      key={job_type.value}
                      value={job_type.value}
                      disabled={job_type.disabled}
                    >
                      <Typography variant='body1'>{job_type.name}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Required</FormHelperText>
            </FormControl>
          </Box>
          <TableContainer component={Paper} sx={{ my: 1 }}>
            <Table sx={{ width: '100%' }} size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Outline Name</TableCell>
                  <TableCell>Instructions</TableCell>
                  <TableCell>Updated</TableCell>
                  <TableCell>Comments</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {populatedProject?.outlines.map((outline) => {
                  return (
                    <TableRow
                      key={outline.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        backgroundColor: theme.palette.grey[50],
                        '&:hover': {
                          backgroundColor: theme.palette.grey[100],
                          boxShadow: 8,
                        },
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          edge='start'
                          checked={checked.indexOf(outline.id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': outline.id }}
                          onClick={handleToggle(outline.id)}
                          disabled={
                            (outline?.outline?.length || 0) === 0 ? true : false
                          }
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: 180,
                        }}
                      >
                        <Tooltip title={outline.name}>
                          <Typography sx={{ fontSize: 'inherit' }}>
                            {outline.name}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Typography
                          sx={{ fontSize: 'inherit', textAlign: 'center' }}
                        >
                          {outline?.outline?.length || 0}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: 'inherit' }}>
                          {outline?.lastUpdated
                            ? new Date(outline.lastUpdated).toLocaleDateString(
                                'en-US',
                                {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                }
                              )
                            : 'Unknown'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography sx={{ fontSize: 'inherit' }}>
                          {(outline?.outline?.length || 0) === 0
                            ? 'Disabled - No outline instructions'
                            : `Prior submissions: ${
                                populatedProject?.jobs?.filter(
                                  (job) => job?.outline === outline.id
                                )?.length
                              }`}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid container>
            {!paymentValidity ? (
              <Button
                color='secondary'
                sx={{ marginLeft: 'auto' }}
                type='submit'
                variant='contained'
                LinkComponent={Link}
                to='/payments'
              >
                Check payment method
              </Button>
            ) : (
              <Button
                sx={{ marginLeft: 'auto' }}
                type='submit'
                variant='contained'
                disabled={!(checked.length > 0 && genType !== '')}
              >
                Submit
              </Button>
            )}
          </Grid>
        </form>
      ) : (
        <Box
          sx={{ width: '100%' }}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress color='secondary' />
        </Box>
      )}
    </>
  );
};

export default OutlineSelection;
