export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const splitEmail = (email) => {
  // Check if the email contains the '@' symbol
  if (email.includes('@')) {
    // Split the email at the '@' symbol
    const parts = email.split('@');
    return parts;
  } else {
    // If the email doesn't contain '@', return an error message
    return 'Invalid email';
  }
};

export const capitalizeFirstLetter = (string) => {
  if (string?.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return string;
  }
};

export const camelize = (string) => {
  if (string?.length > 0) {
    return string
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, '');
  } else {
    return string;
  }
};

export const overflowString = (input, charsToKeep = 24) => {
  if (input?.length > charsToKeep) {
    return input.slice(0, charsToKeep) + '...';
  }

  return input;
};
