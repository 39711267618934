const ALLOWED_FILES_TYPES = [
  'application/pdf',
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/tiff',
];

export const shortenedString = (str) => {
  if (str?.length <= 25) {
    return str; // Return the whole string if it's 15 characters or less
  } else {
    const firstChars = str.slice(0, 10); // Get the first 10 characters
    const lastChars = str.slice(-10); // Get the last 5 characters
    return `${firstChars}...${lastChars}`; // Concatenate with ellipses
  }
};

// Creates a validation object based on list of selected files
export const validateUploadFiles = (selectedFiles, maxFileSizeMB = 10) => {
  let validCount = 0;
  const fileValidationArray = [];

  for (let i = 0; i < selectedFiles.length; i++) {
    const fileValidationObj = {};
    fileValidationObj['name'] = selectedFiles[i].name;
    fileValidationObj['type'] = selectedFiles[i].type;
    fileValidationObj['size'] = selectedFiles[i].size;
    fileValidationObj['success'] = false;
    if (ALLOWED_FILES_TYPES.includes(selectedFiles[i].type)) {
      if (selectedFiles[i].size <= maxFileSizeMB * 1024 * 1024) {
        validCount += 1;
        fileValidationObj['valid'] = true;
        fileValidationObj['message'] = 'Ready to upload';
      } else {
        fileValidationObj['valid'] = false;
        fileValidationObj['message'] = 'Size limits exceeded';
      }
    } else {
      fileValidationObj['valid'] = false;
      fileValidationObj['message'] = 'Unsupported type';
    }
    fileValidationArray.push(fileValidationObj);
  }

  return [validCount, fileValidationArray];
};
