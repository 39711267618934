import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import UploadHeader from './MultiUploadComponents/UploadHeader';
import File from './MultiUploadComponents/File';
import { validateUploadFiles } from './MultiUploadComponents/UploadUtils';
import FilesTable from './MultiUploadComponents/FilesTable';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

const ALLOWED_FILE_COUNT = process.env.REACT_APP_UPLOAD_FILE_LIMIT;
const ALLOWED_FILE_SIZE_MB = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_SIZE_MB;

const MultiFileUpload = ({ project, updateFileList }) => {
  const theme = useTheme();
  const [files, setFiles] = useState([]); // file is the value to be sent to server
  const [currentFile, setCurrentFile] = useState();
  const [dragging, setDragging] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  // const [message, setMessage] = useState();
  const [txRate, setTxRate] = useState(0);
  const [uploadCount, setUploadCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [validCount, setValidCount] = useState(0);
  const [validationArray, setValidationArray] = useState([]);
  const [isSelectionCountOk, setIsSelectionCountOk] = useState(true);

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  const handleDropSelection = (selectedFiles) => {
    if (selectedFiles.length > ALLOWED_FILE_COUNT) {
      setIsSelectionCountOk(false);
      return;
    } else {
      setIsSelectionCountOk(true);
    }

    setFiles(selectedFiles);
    if (selectedFiles.length > 0) {
      setIsComplete(false);
    }
    clearFields();

    const [vc, va] = validateUploadFiles(selectedFiles, ALLOWED_FILE_SIZE_MB);
    setValidCount(vc);
    setValidationArray(va);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    if (
      event.dataTransfer &&
      event.dataTransfer.files &&
      event.dataTransfer.files.length > 0
    ) {
      handleDropSelection(event.dataTransfer.files);
    }
  };

  const handleFileSelect = (event) => {
    event.preventDefault();

    if (event.target.files && event.target.files.length > 0) {
      handleDropSelection(event.target.files);
    }
  };

  const clearFields = () => {
    setUploadProgress({});
    setTxRate(0);
    setUploadCount(0);
    setErrorCount(0);
    setCurrentFile();
    setIsUploading(false);
  };

  const handleReset = () => {
    setFiles([]);
    setIsComplete(false);
    setValidCount(0);
    setValidationArray([]);
    // setMessage();
    clearFields();
  };

  const updateValidationAttribute = (name, key, value) => {
    setValidationArray((prevData) =>
      prevData.map((obj) =>
        obj.name === name ? { ...obj, [key]: value } : obj
      )
    );
  };

  const handleUpload = async () => {
    if (!files) {
      alert('Please select files to upload.');
      return;
    }
    setIsUploading(true);
    for (let i = 0; i < files.length; i++) {
      // Skip files that fail validation
      const fileCheck = validationArray.find(
        (obj) => obj.name === files[i].name
      );
      if (fileCheck && !fileCheck.valid) {
        updateValidationAttribute(files[i].name, 'message', 'Skipped');
        continue;
      }

      const formData = new FormData();
      formData.append('corpusFile', files[i]);
      formData.append('name', files[i].name);
      formData.append('mimeType', files[i].type);
      setCurrentFile(files[i]);
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/files/${project.id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total, rate, progress } = progressEvent;
              const percentCompleted = Math.round((loaded * 100) / total);
              const pctCompleted = Math.floor(progress * 100);
              if (rate > 0) {
                setTxRate((prevState) => (prevState + rate) / 2);
              }
              setUploadProgress({
                ...uploadProgress,
                [files[i].name]: {
                  percentCompleted,
                  bytesUploaded: loaded,
                  totalBytes: total,
                  pctCompleted,
                },
              });
            },
          }
        );
        // console.log(`${files[i].name} - ${response.data}`);
        setUploadCount((prevState) => prevState + 1);
        updateValidationAttribute(
          files[i].name,
          'message',
          'Upload successful'
        );
        updateValidationAttribute(files[i].name, 'success', true);
        updateFileList(data.file);
      } catch (error) {
        // console.error(error.response);
        setErrorCount((prevState) => prevState + 1);
        const msg = error.response?.data.message || 'Upload failed';
        updateValidationAttribute(files[i].name, 'message', msg);
      }
    }
    setIsUploading(false);
    setIsComplete(true);
  };

  // useEffect(() => {
  //   console.log(uploadProgress);
  // }, [uploadProgress]);

  return (
    <Card
      elevation={4}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onDragEnter={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        event.stopPropagation();
        setDragging(false);
      }}
      sx={{
        // width: 480,
        p: 2,
        height: 240,
        background: dragging ? '#dcdcdc' : '#ffffff',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent>
        <Stack
          direction={'column'}
          justifyContent={'space-evenly'}
          height={152}
        >
          {files?.length > 0 && isSelectionCountOk ? (
            <Stack direction={'column'} justifyContent={'center'}>
              <UploadHeader
                selectedFiles={files}
                uploadCount={uploadCount}
                errorCount={errorCount}
                validCount={validCount}
              />

              <FilesTable
                isUploading={isUploading}
                fileValidationArray={validationArray}
                isUploadComplete={isComplete}
              />

              {currentFile && isUploading && (
                <File file={currentFile} uploadProgress={uploadProgress} />
              )}
            </Stack>
          ) : (
            <>
              <Typography variant='smallerFont' textAlign='center'>
                Drop or select files to upload
              </Typography>

              {isSelectionCountOk ? (
                <Typography
                  variant='caption'
                  textAlign='center'
                  color={theme.palette.grey[600]}
                >
                  Limit: {ALLOWED_FILE_COUNT} files or fewer at a time ( Less
                  than {ALLOWED_FILE_SIZE_MB} MB/ file)
                </Typography>
              ) : (
                <Typography
                  variant='smallerFont'
                  color={theme.palette.error.main}
                  textAlign='center'
                >
                  You may upload a maximum of {ALLOWED_FILE_COUNT} files at a
                  time
                </Typography>
              )}

              <Typography
                variant={'caption'}
                textAlign='center'
                color={theme.palette.grey[600]}
              >
                Tip: Upload large files that are over 20MB one at a time.
              </Typography>
              <Typography
                variant={'caption'}
                textAlign='center'
                color={theme.palette.grey[600]}
              >
                Supported types: Text (pdf, txt, word)
              </Typography>
              {/* & Images (gif, jpeg, png) */}

              <Button
                size='small'
                variant='outlined'
                component='label'
                startIcon={<CloudUploadIcon />}
                sx={{ my: 2 }}
              >
                Select File
                <input
                  type='file'
                  multiple
                  hidden
                  onChange={handleFileSelect}
                />
              </Button>
            </>
          )}
        </Stack>
      </CardContent>
      {files?.length > 0 && isSelectionCountOk && (
        <CardActions
          disableSpacing
          sx={{
            justifyContent: 'flex-end',
            mt: 'auto',
            // backgroundColor: theme.palette.grey[300],
          }}
        >
          <>
            {isUploading && (
              <Stack
                direction={'row'}
                spacing={4}
                justifyContent='space-around'
                alignItems={'center'}
              >
                <Typography variant='caption' color={theme.palette.grey[700]}>
                  <CircularProgress size={12} /> &nbsp; Uploading file{' '}
                  {uploadCount + 1} of {validCount} ...
                </Typography>

                <Typography variant='caption' color={theme.palette.grey[700]}>
                  Upload speed: {Math.floor(txRate / 1000)} Kb/s{' '}
                </Typography>
              </Stack>
            )}

            {!isUploading && (
              <Button
                size='small'
                color='secondary'
                variant='outlined'
                sx={{ mx: 1, my: 1 }}
                startIcon={<RestartAltIcon />}
                onClick={handleReset}
              >
                Reset
              </Button>
            )}

            {!isComplete && !isUploading ? (
              <Button
                size='small'
                variant='contained'
                sx={{ mx: 1, my: 1 }}
                startIcon={<CloudUploadIcon />}
                onClick={handleUpload}
              >
                Upload
              </Button>
            ) : null}
          </>
        </CardActions>
      )}
    </Card>
  );
};

export default MultiFileUpload;
