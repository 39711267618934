import React from 'react';
import {
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { style } from '../../../../shared/styles/style';
import ProjectItem from './ProjectItem';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const ProjectList = ({
  userId,
  projects,
  error,
  setIsCreateModalOpen,
  handleSnackbarUpdate,
  handleProjectUpdate,
  handleProjectDelete,
}) => {
  if (error || !projects || projects.length === 0) {
    return (
      <Container maxWidth='lg' sx={{ mt: 2, mb: 2 }}>
        <Grid container justifyContent='center'>
          <Grid item xs={12} md={4}>
            <Paper elevation={4} sx={style.paper}>
              <Typography textAlign='center' variant='h6' color='primary'>
                {error}
              </Typography>
              <br />
              <Typography textAlign='center' variant='h6' color='primary'>
                Create a new project?
              </Typography>

              <Typography textAlign='center' variant='h6' color='primary'>
                <Tooltip title='Create Project'>
                  <IconButton
                    size='large'
                    aria-label='create project'
                    onClick={() => setIsCreateModalOpen(true)}
                  >
                    <NoteAddIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
      <Grid container justifyContent='center' spacing={2}>
        {projects.map((project) => (
          <ProjectItem
            key={project.id}
            userId={userId}
            project={project}
            handleSnackbarUpdate={handleSnackbarUpdate}
            handleProjectUpdate={handleProjectUpdate}
            handleProjectDelete={handleProjectDelete}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default ProjectList;
