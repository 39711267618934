const MILLI_SEC_MAP = {
  s: 1000,
  m: 60 * 1000,
  h: 60 * 60 * 1000,
  d: 24 * 60 * 60 * 1000,
  w: 7 * 24 * 60 * 60 * 1000,
  M: 30 * 24 * 60 * 60 * 1000,
  y: 365 * 24 * 60 * 60 * 1000,
};

export const toMilliSeconds = (timeStr) => {
  const tokens = timeStr.match(/(\d+)([a-zA-Z]+)/); // 7d = [ "7d", "7", "d" ]

  if (tokens?.length === 3) {
    return tokens[1] * (MILLI_SEC_MAP[tokens[2]] || 0);
  } else {
    return 0;
  }
};
