import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import FAQJSON from './faq.json';
import IconTemplate from './components/IconTemplate';

const Help = () => {
  const [topics, setTopics] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [activeQ, setActiveQ] = useState();

  useEffect(() => {
    const countValuesForKey = (array, keyToCount) => {
      return array.reduce((countMap, obj) => {
        const value = obj[keyToCount];
        countMap[value] = (countMap[value] || 0) + 1;
        return countMap;
      }, {});
    };

    // setTopics(Object.entries(countValuesForKey(FAQJSON, 'category')));
    const topicArray = [];
    // convert obj to array of objs
    Object.entries(countValuesForKey(FAQJSON, 'category')).forEach(
      ([key, value]) => {
        topicArray.push({ name: key, count: value });
      }
    );
    setTopics(topicArray);
  }, []);

  const handleTopicSelection = (topic) => {
    const filteredList = FAQJSON.filter((item) => item.category === topic);
    setTopicList(filteredList);
    if (filteredList) {
      handleQuestionSelection(filteredList[0].id);
    }
  };

  const handleQuestionSelection = (id) => {
    const filteredQ = FAQJSON.filter((item) => item.id === id);
    if (filteredQ) {
      setActiveQ(filteredQ[0]);
    }
  };

  return (
    <IconTemplate title='Help' image={HelpIcon}>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        sx={{ pb: 4 }}
      >
        {topics.map((topic, index) => (
          <Button
            key={index}
            variant='text'
            sx={{ mr: 1 }}
            size='small'
            onClick={() => handleTopicSelection(topic.name)}
          >
            {topic.name}
            {/* &nbsp;
            <Chip label={topic.count} size='small' color='primary' /> */}
          </Button>
        ))}
      </Box>

      <Grid container spacing={4}>
        <Grid item xs={6} sm={8}>
          <Paper elevation={0} component={Stack} sx={{ width: '100%' }}>
            {activeQ ? (
              <>
                <Typography variant='h6' color='primary' sx={{ pb: 1 }}>
                  {activeQ.title}
                </Typography>

                <Typography variant='smallerFont' align='justify'>
                  {activeQ.content}
                </Typography>

                {activeQ?.bullets && (
                  <ol>
                    {activeQ?.bullets.map((item, index) => (
                      <li key={index}>
                        <Typography variant='smallerFont' align='justify'>
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ol>
                )}

                {activeQ?.subcontent && (
                  <Typography variant='smallerFont' align='justify'>
                    {activeQ.subcontent}
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography variant='h6' color='primary' sx={{ pb: 1 }}>
                  Start by selecting a topic above.
                </Typography>
                <Typography variant='smallerFont'>
                  Most frequently asked questions have been grouped by
                  topic and category.
                </Typography>
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Paper elevation={0} component={Stack} sx={{ width: '100%' }}>
            {topicList?.length > 0 ? (
              <>
                <Typography variant='h6' color='primary'>
                  {activeQ?.category}
                </Typography>
                {topicList.map((item, index) => (
                  <Typography
                    key={item.id}
                    fontSize={12}
                    align='justify'
                    onClick={() => handleQuestionSelection(item.id)}
                    sx={{
                      cursor: 'pointer',
                      textDecoration: `${
                        item.id === activeQ?.id ? 'underline' : 'none'
                      }`,
                    }}
                  >
                    {index + 1}. {item.title}
                  </Typography>
                ))}
              </>
            ) : (
              ''
            )}
          </Paper>
        </Grid>
      </Grid>
    </IconTemplate>
  );
};

export default Help;
