import React, { useCallback, useState } from 'react';
import axios from 'axios';
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const UserSearch = ({ resCallback }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);

    // Clear previous timeout
    clearTimeout(typingTimeout);

    // Set a new timeout
    if (value.length >= 3) {
      const timeout = setTimeout(() => {
        fetchSearchResults(value);
      }, 500);
      setTypingTimeout(timeout);
    } else {
      resCallback([]);
    }
  };

  const fetchSearchResults = useCallback(
    async (searchQuery) => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/find?q=${searchQuery}`
        );
        resCallback(data.users);
      } catch (error) {
        // console.error('Error fetching data:', error);
        resCallback([]);
      }
      setIsLoading(false);
    },
    [resCallback]
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
      <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
      <TextField
        id='find-collabs'
        label='Find a collaborator by name or email'
        variant='outlined'
        value={query}
        onChange={handleChange}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {isLoading && <CircularProgress size={24} />}
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default UserSearch;
