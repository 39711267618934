import React from 'react';
import Box from '@mui/material/Box';
import { Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { blueGrey } from '@mui/material/colors';

const styles = {
  gridItem: {
    // color: "white",
    // fontFamily: 'Roboto,Helvetica,Arial',
    fontFamily: 'Manrope, sans-serif',
    fontSize: '0.8rem',
    // fontWeight: "bold",
    textDecoration: 'none',
  },
  gridMargin: {
    margin: '2em',
  },
};

const Footer = () => {
  const hidden = useMediaQuery((theme) => theme.breakpoints.up('md'));

  return (
    <Box
      component='footer'
      sx={{
        // py: 1,
        display: 'flex',
        bgcolor: blueGrey[200],
        // bgcolor: theme.palette.grey[300],
        position: 'relative',
      }}
    >
      {hidden ? (
        <Grid
          container
          spacing={2}
          justifyContent='center'
          alignItems='center'
          direction='row'
        >
          <Grid item sx={styles.gridMargin}>
            <Grid container direction='column' spacing={2}>
              <Grid item sx={styles.gridItem} component={Link} to='/terms'>
                Terms of use
              </Grid>
              <Grid item sx={styles.gridItem} component={Link} to='/privacy'>
                Privacy
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={styles.gridMargin}>
            <Grid container direction='column' spacing={2}>
              <Grid item sx={styles.gridItem}>
                Copyright © CorpusKey {new Date().getFullYear()}.
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={styles.gridMargin}>
            <Grid container direction='column' spacing={2}>
              <Grid item sx={styles.gridItem} component={Link} to='/about'>
                About
              </Grid>
              <Grid item sx={styles.gridItem} component={Link} to='/contact'>
                Contact
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ marginLeft: 'auto' }}>
          <Grid container spacing={2}>
            <Grid item sx={{ ...styles.gridItem, ...styles.gridMargin }}>
              Copyright © CorpusKey {new Date().getFullYear()}.
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default Footer;
