import React from 'react';
import { Paper } from '@mui/material';

const defaultPaperSx = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const SimpleCard = ({ sx, children, height, elevation }) => {
  const mergedSx = { height, ...defaultPaperSx, ...sx };
  return (
    <Paper elevation={elevation} sx={mergedSx}>
      {children}
    </Paper>
  );
};

export default SimpleCard;
