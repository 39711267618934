import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import SimpleCard from '../../../shared/components/ui/SimpleCard';
import Tile from './Tile';

const Users = ({ authToken }) => {
  const [userStats, setUserStats] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/admin/user-stats`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        setUserStats(data.stats);
      } catch (error) {
        setError(error.response?.data.message || 'Error processing request.');
      }
    };

    fetchUserData();
  }, [authToken]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant='body1' fontWeight={700} sx={{ mb: 2 }}>
          User Stats
        </Typography>
      </Box>

      {error ? (
        <Alert severity='warning'>{error}</Alert>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <SimpleCard height={90} elevation={4}>
                <Tile caption={userStats?.[0]?.total?.[0]?.count}>Total</Tile>
              </SimpleCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <SimpleCard height={90} elevation={4}>
                <Tile caption={userStats?.[0]?.active?.[0]?.count}>Active</Tile>
              </SimpleCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <SimpleCard height={90} elevation={4}>
                <Tile
                  caption={
                    (userStats?.[0]?.total?.[0]?.count || 0) -
                    (userStats?.[0]?.active?.[0]?.count || 0)
                  }
                >
                  Inactive
                </Tile>
              </SimpleCard>
            </Grid>
          </Grid>

          <SimpleCard sx={{ mt: 2 }}>
            <Typography variant='body2' fontWeight={700}>
              Last 5 users to signup
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Affiliation</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userStats?.[0]?.last5users.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {user.firstname} {user.lastname}
                      </TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.affiliation}</TableCell>
                      <TableCell>
                        {user.accountStatus ? 'Active' : 'Inactive'}
                      </TableCell>
                      <TableCell>
                        {new Date(user.created).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </SimpleCard>
        </>
      )}
    </>
  );
};

export default Users;
