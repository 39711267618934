import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  useTheme,
} from '@mui/material';
import { DataContext } from '../../shared/context/data-context';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import axios from 'axios';
import OutlinesList from './outlines/OutlinesList';
import OutlineCreateModal from './outlines/OutlineCreateModal';
import ProjectStepper from '../../shared/components/ui/ProjectStepper';

const OutlinesPage = () => {
  const dataContext = useContext(DataContext);
  const theme = useTheme();
  const [project, setProject] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [outlinesArray, setOutlinesArray] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    const project = dataContext.getProject();
    setProject(project);
    // console.log(project);

    const fetchOutlines = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/outlines/${project.id}`
        );
        setOutlinesArray(data.outlines);
      } catch (error) {
        setOutlinesArray([]);
        setSnackbarMessage(
          error.response?.data.message || 'Unable to retrieve outlines.'
        );
        setSnackbarOpen(true);
      }
      setIsLoading(false);
    };

    if (project.id) {
      fetchOutlines();
    }

    // return () => {
    //   if (dataContext.getProject()?.name) {
    //     dataContext.storeProject(); // clean up
    //   }
    // };
  }, [dataContext]);

  const handleOutlineCreate = useCallback(
    (outline) => {
      // console.log(outline);
      setOutlinesArray([...outlinesArray, outline]);

      // refresh project context
      dataContext.refreshProject(project?.id);
    },
    [outlinesArray, dataContext, project]
  );

  const handleOutlineDelete = useCallback(
    (id) => {
      const updatedOutlines = outlinesArray.filter((outline) => {
        return outline.id !== id; // FKT - Filter Keeps True
      });
      setOutlinesArray(updatedOutlines);

      // refresh project context
      dataContext.refreshProject(project?.id);
    },
    [outlinesArray, dataContext, project]
  );

  const showSnackBarMessage = useCallback((message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  const handleOutlineUpdate = useCallback(
    (id, newName) => {
      const updatedOutlines = outlinesArray.map((outline) => {
        if (outline.id === id) {
          return { ...outline, name: newName };
        }
        return outline;
      });
      setOutlinesArray(updatedOutlines);
    },
    [outlinesArray]
  );

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      {/* <Typography variant='title'>Project Outlines</Typography>

      <Typography variant='h6' sx={{ mt: 4, pl: 2 }}>
        Project: {project?.name} ({project?.description})
      </Typography> */}

      <ProjectStepper project={project} activeStep={1} />

      {!isLoading && (
        <OutlinesList
          outlines={outlinesArray}
          setIsCreateModalOpen={setIsCreateModalOpen}
          handleSnackbarUpdate={showSnackBarMessage}
          handleOutlineDelete={handleOutlineDelete}
          handleOutlineUpdate={handleOutlineUpdate}
        />
      )}

      <OutlineCreateModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        projectId={project?.id}
        handleSnackbarUpdate={showSnackBarMessage}
        handleOutlineCreate={handleOutlineCreate}
      />

      <SpeedDial
        ariaLabel='SpeedDial create outline'
        sx={{ position: 'absolute', bottom: 72, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          icon={<PlaylistAddIcon />}
          tooltipTitle='Create Outline'
          onClick={() => {
            setIsCreateModalOpen(true);
          }}
        />
      </SpeedDial>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setSnackbarOpen(false)}
          >
            <CancelRoundedIcon fontSize='small' />
          </IconButton>
        }
      />
    </>
  );
};

export default OutlinesPage;
