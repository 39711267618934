import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';
import axios from 'axios';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LockOpenIcon from '@mui/icons-material/LockOpen';
// import CelebrationIcon from '@mui/icons-material/Celebration';
import ReportIcon from '@mui/icons-material/Report';
import { useTheme } from '@mui/material';
import PwdReset from './components/PwdReset';

const Manage = () => {
  // eslint-disable-next-line no-unused-vars
  const [searchParms, setSearchParms] = useSearchParams();
  const { action } = useParams('action');
  const [isComplete, setIsComplete] = useState(false);
  const [message, setMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    setIsComplete(false);
    setMessage(null);
    setErrorMessage(null);

    const activateAccount = async (code) => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/manage/activate?code=${code}`
        );
        setMessage(data.message);
      } catch (error) {
        setErrorMessage(
          error.response?.data.message ||
            'Unable to activate the account. Please try again.'
        );
      }
    };

    if (action === 'activate' && searchParms.get('code')) {
      activateAccount(searchParms.get('code'));
    }

    setIsComplete(true);
  }, [action, searchParms]);

  return (
    <>
      <Backdrop
        sx={{
          color: theme.palette.secondary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={!isComplete}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {action === 'reset' ? (
            <LockOpenIcon
              sx={{ fontSize: '3.5em', color: theme.palette.secondary.main }}
            />
          ) : (
            <ManageAccountsIcon
              sx={{ fontSize: '3.5em', color: theme.palette.secondary.main }}
            />
          )}

          {action === 'activate' && searchParms.get('code') && (
            <>
              <Typography component="h1" variant="h5">
                Account Activation
              </Typography>

              {message && (
                <Alert
                  icon={<ReportIcon fontSize="inherit" />}
                  severity="success"
                  variant="outlined"
                  sx={{ my: 2, width: '80%' }}
                >
                  <AlertTitle>Account activated!</AlertTitle>
                  {message}
                </Alert>
              )}

              {errorMessage && (
                <Alert
                  icon={<ReportIcon fontSize="inherit" />}
                  severity="error"
                  variant="outlined"
                  sx={{ my: 2, width: '80%' }}
                >
                  <AlertTitle>Unable to activate account</AlertTitle>
                  {errorMessage}
                </Alert>
              )}
            </>
          )}

          {action === 'reset' && searchParms.get('code') && (
            <>
              <Typography component="h1" variant="h5">
                Reset Password
              </Typography>
              {!message && !errorMessage && (
                <PwdReset
                  code={searchParms.get('code')}
                  setResMessage={setMessage}
                  setResErrorMessage={setErrorMessage}
                />
              )}

              {message && (
                <Alert
                  icon={<ReportIcon fontSize="inherit" />}
                  severity="success"
                  variant="outlined"
                  sx={{ my: 2, width: '80%' }}
                >
                  <AlertTitle>Password has been reset</AlertTitle>
                  {message}
                </Alert>
              )}

              {errorMessage && (
                <Alert
                  icon={<ReportIcon fontSize="inherit" />}
                  severity="error"
                  variant="outlined"
                  sx={{ my: 2, width: '80%' }}
                >
                  <AlertTitle>Unable to reset password</AlertTitle>
                  {errorMessage}
                </Alert>
              )}
            </>
          )}

          {!searchParms.get('code') ? (
            <>
              <Alert
                icon={<ReportIcon fontSize="inherit" />}
                severity="error"
                variant="outlined"
                sx={{ my: 2, width: '80%' }}
              >
                <AlertTitle>Missing Information</AlertTitle>
                <div>
                  Appears that you may be missing some information to process
                  your account management request.
                </div>
                <div>
                  Please check your email and try again or reach out to{' '}
                  {` ${process.env.REACT_APP_SUPPORT_EMAIL} `} for assistance.
                </div>
              </Alert>
            </>
          ) : null}

          {!(action === 'activate' || action === 'reset') ? (
            <Alert
              severity="warning"
              variant="outlined"
              sx={{ my: 2, width: '80%' }}
            >
              <AlertTitle>Unknown Action</AlertTitle>
              We're not really sure how to "{action}" your account. <br />
              If you know how, please reach out to us at{' '}
              {` ${process.env.REACT_APP_SUPPORT_EMAIL} `}
              and we'd be happy to help.
            </Alert>
          ) : null}

          {/* {!isComplete ? <Typography>Processing request...</Typography> : null} */}
        </Box>
      </Container>
    </>
  );
};

export default Manage;
