import React from 'react';
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '../../../shared/components/ui/LoadingButton';
import PasswordIcon from '@mui/icons-material/Password';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const resetSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Must be atleast 8 characters')
    .required('Required'),
});

const PwdReset = ({ code, setResMessage, setResErrorMessage }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: { password: '' },
    resolver: yupResolver(resetSchema),
  });

  const handleReset = React.useCallback(
    async (formData) => {
      setResMessage(null);
      setResErrorMessage(null);
      setIsLoading(true);

      try {
        // eslint-disable-next-line no-unused-vars
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/manage/reset?code=${code}`,
          formData,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        setResMessage(data.message);
        reset();
      } catch (error) {
        setResErrorMessage(
          error.response?.data.message ||
            'Unable to reset password. Please try again.'
        );
      }

      setIsLoading(false);
    },
    [reset, code, setResMessage, setResErrorMessage]
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={2}
    >
      <Grid item xs={8}>
        <Box
          component="form"
          onSubmit={handleSubmit(handleReset)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            {...register('password')}
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="new-password"
            variant="filled"
            {...(errors.password && {
              error: true,
              helperText: errors.password.message,
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 2, mb: 2 }}
            isLoading={isLoading}
          >
            Reset Password
          </LoadingButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PwdReset;
