import React from 'react';
import { Container, Grid, Typography } from '@mui/material';

const ContentBody = ({ final, bullets, subtitle, definition }) => {
  return (
    <Container maxWidth="md" sx={{ mt: 2, mb: 1 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {subtitle && (
          <Grid item xs={12}>
            <Typography variant="body2" fontWeight="bold">
              {subtitle}
            </Typography>
          </Grid>
        )}

        {final && (
          <Grid item xs={12}>
            <Typography variant="body2">{final}</Typography>
          </Grid>
        )}

        {definition && (
          <Grid item xs={12}>
            <Typography variant="body2" fontStyle="italic">
              {definition}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default ContentBody;
