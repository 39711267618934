import React, { Suspense } from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';

import MainNav from './shared/components/navigation/MainNav';
import { CircularProgress, ThemeProvider } from '@mui/material';
import { useAuth } from './shared/hooks/use-auth';
import { AuthContext } from './shared/context/auth-context';
import { useContextData } from './shared/hooks/use-context-data';
import { DataContext } from './shared/context/data-context';
import theme2 from './shared/theme/theme2';
import ScrollToTop from './utils/ScrollToTop';

import Home from './pages/home/Home';
import Login from './pages/user/Login';
import SignUp from './pages/user/Signup';
import Footer from './shared/components/navigation/Footer';
import Manage from './pages/user/Manage';
import Products from './pages/public/Products';
import About from './pages/public/About';
import Contact from './pages/public/Contact';
import Help from './pages/public/Help';
import Terms from './pages/public/Terms';
import Privacy from './pages/public/Privacy';
import Page404 from './pages/public/Page404';
import LandingPage from './pages/project/LandingPage';
import FileManagementPage from './pages/project/FileManagementPage';
// import OutlinePage from './pages/outline/OutlinePage';
import OutlinePageMRT from './pages/outline/OutlinePageMRT';
import OutlinesPage from './pages/outline/OutlinesPage';
import GeneratePage from './pages/generate/GeneratePage';
import ContentLandingPage from './pages/content/ContentLandingPage';
import Collaborate from './pages/collaborate';
import Invite from './pages/invite';
import AdminPage from './pages/admin';
// import PaymentHome from './pages/payment';
import UserSettings from './pages/user/UserSettings';

// Lazy loaded
// const Help = React.lazy(() => import('./pages/public/Help'));
// const LandingPage = React.lazy(() => import('./pages/project/LandingPage'));
// const FileManagementPage = React.lazy(() =>
//   import('./pages/project/FileManagementPage')
// );
// const OutlinePage = React.lazy(() => import('./pages/outline/OutlinePage'));
// const OutlinesPage = React.lazy(() => import('./pages/outline/OutlinesPage'));
// const GeneratePage = React.lazy(() => import('./pages/generate/GeneratePage'));
// const ContentLandingPage = React.lazy(() =>
//   import('./pages/content/ContentLandingPage')
// );
const PaymentHome = React.lazy(() => import('./pages/payment'));

const App = () => {
  const { userId, token, user, login, logout } = useAuth();
  const { getProject, storeProject, refreshProject } = useContextData();

  let routes = (
    <>
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/signup' element={<SignUp />} />
      <Route path='/manage/:action' element={<Manage />} />
      <Route path='/products' element={<Products />} />
      <Route path='/about' element={<About />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/help' element={<Help />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/terms' element={<Terms />} />
      <Route path='/payments' element={<PaymentHome />} />
      <Route path='/404' element={<Page404 />} />
      <Route path='*' element={<Navigate to='/' replace />} />
      {token && (
        <>
          <Route path='/landing' element={<LandingPage />} />
          <Route path='/files/:projectId' element={<FileManagementPage />} />
          <Route path='/outline/:outlineId' element={<OutlinePageMRT />} />
          <Route path='/outlines/:projectId' element={<OutlinesPage />} />
          <Route path='/generate/:projectId' element={<GeneratePage />} />
          <Route path='/content/:projectId' element={<ContentLandingPage />} />
          <Route path='/collab/:projectId' element={<Collaborate />} />
          <Route path='/settings' element={<UserSettings />} />
          <Route path='/invite' element={<Invite />} />
          <Route path='/admin' element={<AdminPage />} />
        </>
      )}
    </>
  );
  return (
    <AuthContext.Provider
      value={{ isLoggedIn: !!token, userId, token, user, login, logout }}
    >
      <DataContext.Provider
        value={{ getProject, storeProject, refreshProject }}
      >
        <ThemeProvider theme={theme2}>
          <Router>
            <ScrollToTop />
            <MainNav />
            <div style={{ minHeight: '85vh' }}>
              <main>
                <Suspense fallback={<CircularProgress color='secondary' />}>
                  <Routes>{routes}</Routes>
                </Suspense>
              </main>
            </div>
            <Footer />
          </Router>
        </ThemeProvider>
      </DataContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
