import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

const FMDetails = ({ project }) => {
  return (
    <TableContainer sx={{ pt: 2 }}>
      <Table size='small'>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
              <Typography variant='smallerFont'>Name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='smallerFont'>{project?.name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
              <Typography variant='smallerFont'>Description</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='smallerFont'>
                {project?.description}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
              <Typography variant='smallerFont'>Status</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='smallerFont'>{project?.status}</Typography>
            </TableCell>
          </TableRow>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>
              <Typography variant='smallerFont'>Created</Typography>
            </TableCell>
            <TableCell>
              <Typography variant='smallerFont'>
                {project?.created
                  ? new Date(project.created).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                    })
                  : null}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FMDetails;
