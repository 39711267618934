import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepButton,
  Typography,
  Breadcrumbs,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, useNavigate } from 'react-router-dom';
import { DataContext } from '../../context/data-context';
import DashboardIcon from '@mui/icons-material/Dashboard';

// const steps = ['Upload Corpus Files', 'Manage Module Oulines', 'Generate Content', 'Results'];

const ProjectStepper = ({ activeStep, crumb }) => {
  const [project, setProject] = useState();
  const navigate = useNavigate();
  const dataContext = useContext(DataContext);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    setProject(dataContext.getProject());
  }, [dataContext]);

  useEffect(() => {
    const crumbs = [
      <Link
        to={'/landing'}
        key='1'
        style={{
          textDecoration: 'none',
        }}
      >
        <Typography color='primary' fontSize={18} fontWeight={700}>
          <DashboardIcon fontSize='small' /> Dashboard
        </Typography>
      </Link>,

      <Typography key='2' color='text.primary' fontSize={18} fontWeight={700}>
        {project?.name}
      </Typography>,
    ];

    if (crumb) {
      // crumbs.push({ crumb });
      crumbs.push(
        <Typography key='3' color='text.primary' fontSize={18} fontWeight={700}>
          {crumb}
        </Typography>
      );
    }

    setBreadcrumbs(crumbs);
  }, [crumb, project]);

  const steps = [
    { id: 0, label: 'Manage Corpus Files', link: `/files/${project?.id}` },
    {
      id: 1,
      label: 'Manage Module Outlines',
      link: `/outlines/${project?.id}`,
    },
    { id: 2, label: 'Generate Content', link: `/generate/${project?.id}` },
    { id: 3, label: 'Results', link: `/content/${project?.id}` },
  ];

  const handleStep = (step) => () => {
    navigate(step.link);
  };

  const isStepComplete = (index) => {
    if (!project) return false;

    switch (index) {
      case 0: // files
        return project?.files?.length > 0 ? true : false;
      case 1: // outline
        return project?.outlines?.length > 0 ? true : false;
      case 2: // jobs
        return project?.jobs?.length > 0 ? true : false;
      default:
        return false;
    }
  };

  return (
    <>
      <Box
        sx={{ width: '100%', py: 2 }}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize='small' />}
          aria-label='breadcrumb'
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>
      <Box sx={{ width: '100%' }} justifyContent='center' alignItems='center'>
        <Stepper nonLinear activeStep={activeStep} alternativeLabel={true}>
          {steps.map((step, index) => (
            <Step
              key={step.id}
              completed={isStepComplete(index)}
              sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: 'success.main', // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                  {
                    color: 'grey.500', // Just text label (COMPLETED)
                  },
                '& .MuiStepLabel-root .Mui-active': {
                  color: 'primary.main', // circle color (ACTIVE)
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                  {
                    color: 'primary.main', // Just text label (ACTIVE)
                    // textDecoration: 'underline dotted',
                    fontWeight: 'bold',
                  },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'common.white', // circle's number (ACTIVE)
                },
              }}
            >
              <StepButton onClick={handleStep(step)} disableRipple={true}>
                {step.label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

export default ProjectStepper;
