import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import {
  Box,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  IconButton,
  Tooltip,
  useTheme,
  Typography,
} from '@mui/material';

import PaymentIcon from '@mui/icons-material/Payment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SendIcon from '@mui/icons-material/Send';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Logout from '@mui/icons-material/Logout';

const AccountMenu = ({ setHideIndicator, auth }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* <Box sx={{ marginLeft: 'auto' }}> */}
      <Box>
        <Tooltip title='Account'>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar
              sx={{
                width: 32,
                height: 32,
                // bgcolor: 'secondary.main',
                // color: 'text.secondary',
                bgcolor: 'primary.light',
                color: 'white',
              }}
            >
              {auth?.user?.firstname ? auth.user.firstname[0] : ''}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 12,
            sx: {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: theme.palette.primary.main,
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {auth?.user && (
          <div>
            <Typography
              sx={{ fontSize: 14, px: 2 }}
              textAlign='center'
              color='lightgray'
            >
              {auth.user.firstname} {auth.user.lastname} <br />
              {auth.user.email}
            </Typography>
            <Divider sx={{ py: 1 }} />
          </div>
        )}

        <MenuItem
          onClick={() => {
            handleClose();
            setHideIndicator(true);
            navigate('/landing');
          }}
          sx={{ pt: 2 }}
        >
          <ListItemIcon>
            <DashboardIcon
              fontSize='small'
              style={{ color: theme.palette.common.white }}
            />
          </ListItemIcon>
          Dashboard
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            setHideIndicator(true);
            navigate('/payments');
          }}
        >
          <ListItemIcon>
            <PaymentIcon
              fontSize='small'
              style={{ color: theme.palette.common.white }}
            />
          </ListItemIcon>
          Payments
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            setHideIndicator(true);
            navigate('/settings');
          }}
        >
          <ListItemIcon>
            <ManageAccountsIcon
              fontSize='small'
              style={{ color: theme.palette.common.white }}
            />
          </ListItemIcon>
          Settings
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleClose();
            setHideIndicator(true);
            navigate('/invite');
          }}
        >
          <ListItemIcon>
            <SendIcon
              fontSize='small'
              style={{ color: theme.palette.common.white }}
            />
          </ListItemIcon>
          Invite a collaborator
        </MenuItem>

        {auth.user.su && (
          <MenuItem
            onClick={() => {
              handleClose();
              setHideIndicator(true);
              navigate('/admin');
            }}
          >
            <ListItemIcon>
              <AdminPanelSettingsIcon
                fontSize='small'
                style={{ color: theme.palette.error.light }}
              />
            </ListItemIcon>
            Site administration
          </MenuItem>
        )}

        <Divider />

        <MenuItem
          onClick={() => {
            handleClose();
            setHideIndicator(true);
            auth.logout();
          }}
        >
          <ListItemIcon>
            <Logout
              fontSize='small'
              style={{ color: theme.palette.common.white }}
            />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};
export default AccountMenu;
