export const getOperatingSystem = (window) => {
  let operatingSystem = "Unknown";
  try {
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    } else if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    } else if (window.navigator.appVersion.indexOf("X11") !== -1) {
      operatingSystem = "UNIX OS";
    } else if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    }
  } catch (error) {}

  return operatingSystem;
};

export const getBrowser = (window) => {
  let currentBrowser = "Unknown";
  try {
    if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
      currentBrowser = "Google Chrome";
    } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
      currentBrowser = "Mozilla Firefox";
    } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
      currentBrowser = "Internet Explorer";
    } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
      currentBrowser = "Edge";
    } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
      currentBrowser = "Safari";
    } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
      currentBrowser = "Opera";
    }
  } catch (error) {}

  return currentBrowser;
};
