import React from 'react';
import {
  Container,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import OutlinesItem from './OutlinesItem';
import { style } from '../../../shared/styles/style';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const OutlinesList = ({
  outlines,
  setIsCreateModalOpen,
  handleOutlineDelete,
  handleOutlineUpdate,
  handleSnackbarUpdate,
}) => {
  if (!outlines || outlines.length === 0) {
    return (
      <Container maxWidth='lg' sx={{ mt: 2, mb: 2 }}>
        <Grid container justifyContent='center'>
          <Grid item xs={12} md={4}>
            <Paper elevation={4} sx={style.paper}>
              <Typography textAlign='center' variant='h6' color='primary'>
                Create a new outline?
              </Typography>

              <Typography textAlign='center' variant='h6' color='primary'>
                <Tooltip title='Create Outline'>
                  <IconButton
                    size='large'
                    aria-label='create project'
                    onClick={() => setIsCreateModalOpen(true)}
                  >
                    <PlaylistAddIcon fontSize='inherit' />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
      <Grid container justifyContent='center' spacing={2}>
        {outlines.map((outline) => (
          <OutlinesItem
            key={outline.id}
            outline={outline}
            handleSnackbarUpdate={handleSnackbarUpdate}
            handleOutlineDelete={handleOutlineDelete}
            handleOutlineUpdate={handleOutlineUpdate}
          />
        ))}
      </Grid>
    </Container>
  );
};

export default OutlinesList;
