import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';

function Title({ sx, icon, children }) {
  return (
    <Stack alignItems='center' direction='row' gap={2}>
      {icon && <Box>{icon}</Box>}
      <Typography
        component='h2'
        variant='h6'
        color='primary'
        gutterBottom
        {...sx}
      >
        {children}
      </Typography>
    </Stack>
  );
}

export default Title;
