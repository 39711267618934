import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Snackbar,
  SpeedDial,
  SpeedDialAction,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../../shared/context/auth-context';
import { DataContext } from '../../shared/context/data-context';
import ProjectList from './components/project/ProjectList';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ProjectCreateModal from './components/project/ProjectCreateModal';
import IconTemplate from '../public/components/IconTemplate';
import DashboardIcon from '@mui/icons-material/Dashboard';

const LandingPage = () => {
  const theme = useTheme();
  const auth = useContext(AuthContext);
  const dataContext = useContext(DataContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedProjects, setLoadedProjects] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const handleSnackbarUpdate = useCallback((message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }, []);

  useEffect(() => {
    // clear the project from the context, it's set by the action links
    if (dataContext.getProject()?.name) {
      dataContext.storeProject(); // clean up
    }

    const fetchProjects = async () => {
      setIsLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/projects/${auth.userId}`
        );
        setLoadedProjects(data.projects);
        handleSnackbarUpdate('Projects loaded.');
      } catch (error) {
        setLoadedProjects([]);
        handleSnackbarUpdate(
          error.response?.data.message || 'Error processing request.'
        );
        setError(error.response?.data.message || 'Error processing request.');
      }
      setIsLoading(false);
    };

    fetchProjects();
  }, [auth.userId, handleSnackbarUpdate, dataContext]);

  const handleProjectUpdate = useCallback(
    (id, newName, newDesc) => {
      const updatedProjects = loadedProjects.map((project) => {
        if (project.id === id) {
          return { ...project, name: newName, description: newDesc };
        }
        return project;
      });
      setLoadedProjects(updatedProjects);
    },
    [loadedProjects]
  );

  const handleProjectCreate = useCallback(
    (project) => {
      setError(null);
      setLoadedProjects([...loadedProjects, project]);
    },
    [loadedProjects]
  );

  const handleProjectDelete = useCallback(
    (id) => {
      const updatedProjects = loadedProjects.filter((project) => {
        return project.id !== id; // FKT - Filter Keeps True
      });
      setLoadedProjects(updatedProjects);
    },
    [loadedProjects]
  );

  return (
    <>
      <IconTemplate title='Project Dashboard' image={DashboardIcon} />

      {isLoading && (
        <Backdrop
          sx={{
            color: theme.palette.secondary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading}
        >
          <CircularProgress color='secondary' />
        </Backdrop>
      )}

      {!isLoading && loadedProjects && (
        <ProjectList
          userId={auth.userId}
          projects={loadedProjects}
          error={error}
          setIsCreateModalOpen={setIsCreateModalOpen}
          handleSnackbarUpdate={handleSnackbarUpdate}
          handleProjectUpdate={handleProjectUpdate}
          handleProjectDelete={handleProjectDelete}
        />
      )}

      <ProjectCreateModal
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
        userId={auth.userId}
        handleSnackbarUpdate={handleSnackbarUpdate}
        handleProjectCreate={handleProjectCreate}
      />

      <SpeedDial
        ariaLabel='SpeedDial create Project'
        sx={{ position: 'absolute', bottom: 72, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          icon={<NoteAddIcon />}
          tooltipTitle='Create Project'
          onClick={() => {
            setIsCreateModalOpen(true);
          }}
        />
      </SpeedDial>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={
          <IconButton
            size='small'
            aria-label='close'
            color='inherit'
            onClick={() => setSnackbarOpen(false)}
          >
            <CancelRoundedIcon fontSize='small' />
          </IconButton>
        }
      />
    </>
  );
};

export default LandingPage;
