import React from 'react';
import { Avatar, Box, Container, Icon, Typography } from '@mui/material';

const IconTemplate = ({ title, image, children, ...props }) => {
  return (
    <Container {...props}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ mt: 1, bgcolor: 'secondary.main' }}>
          <Icon component={image} fontSize='small' />
        </Avatar>
        <Typography fontSize={20} fontWeight={700} sx={{ mb: 1 }}>
          {title}
        </Typography>

        {children}
      </Box>
    </Container>
  );
};

export default IconTemplate;
