import React from 'react';
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { shortenedString } from './UploadUtils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';

const StyledTableCell = styled(TableCell)({
  padding: '2px 8px 0px 8px',
  backgroundColor: 'lightgray',
});

const FilesTable = ({
  isUploading = false,
  fileValidationArray = [],
  isUploadComplete = false,
}) => {
  const theme = useTheme();

  const getMessageColor = (item) => {
    if (!isUploadComplete) {
      if (item.valid) {
        return theme.palette.success.main;
      } else {
        return theme.palette.warning.dark;
      }
    } else {
      if (item.success) {
        return theme.palette.success.main;
      } else {
        return theme.palette.error.main;
      }
    }
  };

  const getIcon = (item) => {
    if (!isUploadComplete) {
      if (item.valid) {
        return <DoneIcon sx={{ fontSize: 12 }} />;
      } else {
        return <BlockIcon sx={{ fontSize: 12 }} />;
      }
    } else {
      if (item.success) {
        return <CheckCircleIcon sx={{ fontSize: 12 }} />;
      } else {
        return <CancelIcon sx={{ fontSize: 12 }} />;
      }
    }
  };

  if (!isUploading) {
    return (
      <Stack
        direction={'column'}
        spacing={0}
        height={120}
        sx={{ overflow: 'auto', mt: 2 }}
      >
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography variant='caption' fontWeight={'bold'}>
                    #
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography variant='caption' fontWeight={'bold'}>
                    File
                  </Typography>
                </StyledTableCell>
                {/* <StyledTableCell>
                  <Typography variant='caption'>Valid</Typography>
                </StyledTableCell> */}
                <StyledTableCell>
                  <Typography variant='caption' fontWeight={'bold'}>
                    Status
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fileValidationArray.map((item, index) => (
                <TableRow key={index}>
                  <StyledTableCell>
                    <Typography variant='caption'>{index + 1}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant='caption'>
                      {shortenedString(item.name)}
                    </Typography>
                  </StyledTableCell>
                  {/* <StyledTableCell>
                    <Typography variant='caption'>
                      {item.valid ? 'Yes' : 'No'}
                    </Typography>
                  </StyledTableCell> */}
                  <StyledTableCell>
                    <Typography
                      variant='caption'
                      alignItems='center'
                      color={getMessageColor(item)}
                    >
                      {getIcon(item)} {item.message}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    );
  } else {
    return <></>;
  }
};

export default FilesTable;
